<section class="header">
  <div class="banner-content">
    <div class="header-title text-center header-title-minWidth">
      <div class="painting-icon-top">
        <svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.799 0.472069C47.17 -0.157356 46.1495 -0.157356 45.5205 0.472069L33.1019 12.8906H4.94141C2.276 12.8906 0.107422 15.0592 0.107422 17.7246V40.2828C0.107422 42.9486 2.276 45.1168 4.94141 45.1168H6.66016V53.3883C6.66016 54.0433 7.0567 54.6333 7.66305 54.8805C8.27821 55.1314 8.97183 54.9765 9.42334 54.5154L18.6172 45.1168H37.3829C40.0483 45.1168 42.2168 42.9486 42.2168 40.2828V22.0056L54.6354 9.58699C55.2644 8.95756 55.2644 7.93748 54.6354 7.30805L47.799 0.472069ZM32.9869 26.6776L28.4295 22.1201L42.1023 8.44773L46.6598 13.0052L32.9869 26.6776ZM26.7128 24.9609L30.1461 28.3942L24.2614 30.8456L26.7128 24.9609ZM38.9942 40.2828C38.9942 41.1716 38.2712 41.8941 37.3829 41.8941H17.9395C17.506 41.8941 17.0906 42.0691 16.7876 42.3788L9.88282 49.4372V43.5055C9.88282 42.6159 9.1615 41.8941 8.27149 41.8941H4.94141C4.05308 41.8941 3.33008 41.1716 3.33008 40.2828V17.7246C3.33008 16.8359 4.05308 16.1133 4.94141 16.1133H29.8788L25.0113 20.9809C24.877 21.1151 24.744 21.3002 24.6554 21.5192L20.1953 32.2266H11.4942C10.6041 32.2266 9.88282 32.9479 9.88282 33.8379C9.88282 34.7275 10.6041 35.448 11.4942 35.448H21.2687H21.27C21.4773 35.448 21.6997 35.4056 21.9032 35.3183L33.5878 30.4516C33.7825 30.3757 33.9789 30.2435 34.1266 30.0958L38.9942 25.2282V40.2828ZM48.9383 10.7263L44.3808 6.16879L46.6598 3.89027L51.2172 8.44773L48.9383 10.7263Z" fill="black"/>
        </svg>                   
      </div>
      <h2>The Book UI/UX Blog</h2>
      <div class="blogMenu">
        <ul>
          <li class="blogMenu-item">
            <a class="blogMenu-link active">UI/UX</a>
          </li>
          <li class="blogMenu-item">
            <a class="blogMenu-link">ICON & Logo</a>
          </li>
          <li class="blogMenu-item">
            <a class="blogMenu-link">Design Principles</a>
          </li>
          <li class="blogMenu-item">
            <a class="blogMenu-link">CSS Design Principles</a>
          </li>
          <li class="blogMenu-item">
            <a class="blogMenu-link">photography Basics Principles</a>
          </li>
          <li class="blogMenu-item">
            <a class="blogMenu-link">Colors Principles</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="blogbox">
  <div class="container">
    <div class="row">

      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="blogitem">
          <div class="blogImg">
            <a class="blogImgLink"><img alt="camera, mobile icon" src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fblog_one.jpg?alt=media&token=a7e3a765-4b2c-40e9-a909-0a0ddd9850ed" /></a>
          </div>
          <div class="blogContentWrapper">
            <div class="blogCategary-wrapper">
              <div class="blogCategaryName blue">
                UI/UX
              </div>
              <div class="LikeCount">
                <span class="likeIconContContent">12</span>
                <a class="likeIcon"></a>
              </div>
            </div>
            <div class="blogTitle">
              <h3 class="blogTitleContent">
                <a class="blogTitleLink">How To Create a Timeline
                Infographic in 5 Easy Steps</a></h3>
              <p class="blogContent">28 Absolute Best Graphic Design
                Blogs to Inspire You in 2020</p>
            </div>
            <div class="blogfooter">
              <span class="blogCreateContent">By<b class="BlogCraterName">Annasaheb Kute </b> May 16 2020</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
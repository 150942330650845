<!-- <div class="web-template-section">
  <div class="web-content-section">
    <h2 class="my-recent">Better design, better experiences.</h2>
   <div class="">
      <div class="web-template-wrapper">
        <div class="web-box web-teplate4box">
          <div class="web-row">
            <div class="web-innerbox ">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fhome_img1-min.jpg?alt=media&token=ccdf655d-e3d3-4cbd-984e-f875e1712a15" />
            </div>
            <div class="web-innerbox bg-black">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fpostmates-logomark-min.png?alt=media&token=1c91ecbd-6d09-489d-b78f-8153389773f4">
            </div>
          </div>
          <div class="web-row">
            <div class="web-innerbox ">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fhome_img3-min.jpg?alt=media&token=7eaf15cf-6bac-42ca-a861-4877695c3b21" />
            </div>
            <div class="web-innerbox ">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fhome_img2-min.jpg?alt=media&token=eaaaf32d-3532-48a9-acf4-c73306b51379" />
            </div>
          </div>
        </div>
        <div class="web-box web-teplate1box">
          <div class="web-innerbox-one ">
            <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fmobile-min.jpg?alt=media&token=bbe61e7d-33fa-48b4-a2bd-028667ebec35" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<section class="web-template">
  <div class="container">
    <div class="row">
      <h2 class="web-template-title">
        <span>
          My Best recently completed
          selective work
        </span>
      </h2>
      <div class="mywork">
        <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fportfolio-my-work.png?alt=media&token=c6227246-432d-4359-ae0a-4e95373bf6a6" />
        <div class="view-more-rout-box">
          <a class="view-more-st" routerLink="/mywork">
            View More 
            <span class="view-more-icon-st">
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.35355 4.35355C8.54882 4.15829 8.54882 3.84171 8.35355 3.64645L5.17157 0.464466C4.97631 0.269204 4.65973 0.269204 4.46447 0.464466C4.2692 0.659728 4.2692 0.976311 4.46447 1.17157L7.29289 4L4.46447 6.82843C4.2692 7.02369 4.2692 7.34027 4.46447 7.53553C4.65973 7.7308 4.97631 7.7308 5.17157 7.53553L8.35355 4.35355ZM0 4.5H8V3.5H0V4.5Z" fill="#FD636B"/>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="header">
  <div class="banner-content">
    <div class="header-title text-center">
      <h2>Insets</h2>
      <p>
        Here are the Most Inspiring Photography My Collections
      </p>
    </div>
  </div>
</section>
<section class="product-box">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2Foriental_garden_lizard_optimized.jpg?alt=media&token=a3b43582-c8c1-48ee-bd03-78d34cb41900" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Lizard</h3>
            <p class="photographyContent">Lizards are a good-luck signs due to their sneaky abilities. Because this animal is mainly nocturnal, it has become a symbol for good vision and protection against the unseen things in life. This photograph was taken at Pune Kamshet, That time I had used cannon 200D and cannon lens.</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2Fdragonfly_optimized.jpg?alt=media&token=25de1906-fe18-484b-97a1-63b201e58ccc" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Dragonfly</h3>
            <p class="photographyContent">Dragonflies (suborder Anisoptera) are heavy-bodied, strong-flying insects that hold their wings horizontally both in flight and at rest. ... An adult dragonfly has three distinct segments, the head, thorax, and abdomen as in all insects</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2Fbutterflies_optimized.jpg?alt=media&token=ddf45623-1cf5-4961-902b-a424b9305f74" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Butterfly</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 
        
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2FPhotography11-min.jpg?alt=media&token=ea0e9506-d2d2-44e3-95ba-d2f55ff09f2d" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Butterfly</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2FPhotography2-min.jpg?alt=media&token=8777cfb7-f93c-406f-a213-a411adca34bc" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Lizard</h3>
            <p class="photographyContent">Dragonflies (suborder Anisoptera) are heavy-bodied, strong-flying insects that hold their wings horizontally both in flight and at rest. ... An adult dragonfly has three distinct segments, the head, thorax, and abdomen as in all insects</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2FPhotography7-min.jpg?alt=media&token=29b7f82e-eb75-4ec4-adf0-f23922c2a8ef" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Insets </h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2FjewelBugs-min.jpg?alt=media&token=aacf78b2-11b7-45b4-bc17-ffbc806aec6f" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Insets </h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>
         
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2Fhousefly-min.jpg?alt=media&token=60e38e50-c35e-4846-b71a-5380069de822" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Insets </h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

      </div>
    </div>
  </div>
</section>
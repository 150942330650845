import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/icon/.test(loc)) {
        $('body').addClass('tech');
        $('body').removeClass('home');
        $('body').removeClass('about-bg');
      }
      /* Popup */
      $(".detailsPage, .iconImg").on("click", function(){
        $(".iconPopup").toggle();
        var iconName = $(this).parents(".iconBox").children(".iconfooter").children(".iconName").text();
        var iconIndestry = $(this).parents(".iconBox").children(".iconfooter").children(".iconIndestry").text();
        var iconCategary = $(this).parents(".iconBox").children(".iconfooter").children(".iconCategary").text();
        var iconImg = $(this).parents(".iconBox").children(".iconImg").children("img").attr('src');
        var splitUrl = iconImg.split('/');
        var fileName = splitUrl[5].split('.');
        var filenameSplit = fileName[0];
        /*
        *PNG, SVG , PSD 
        *All Download
        *file name should be same folder name 
        */
        //PNG
        var pngDownload = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PNG/32"+"/"+filenameSplit+".png";
        var download16 = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PNG/16"+"/"+filenameSplit+".png";
        var download24 = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PNG/24"+"/"+filenameSplit+".png";
        var download32 = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PNG/32"+"/"+filenameSplit+".png";
        var download64 = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PNG/64"+"/"+filenameSplit+".png";
        $("a.icon-png").attr('download', pngDownload);
        $("a.download16").attr('download', download16);
        $("a.download24").attr('download', download24);
        $("a.download32").attr('download', download32);
        $("a.download64").attr('download', download64);
        $("a.download16").attr('href', download16);
        $("a.download24").attr('href', download24);
        $("a.download32").attr('href', download32);
        $("a.download64").attr('href', download64);
        $("a.icon-png").attr('href', pngDownload);
        //SVG
        var svgDownload = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/SVG"+"/"+filenameSplit+".svg";
        $("a.icon-SVG").attr('download', svgDownload);
        $("a.icon-SVG").attr('href', svgDownload);
        //PSD
        var psdDownload = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/PSD"+"/"+filenameSplit+".psd";
        $("a.icon-PSD").attr('download', psdDownload);
        $("a.icon-PSD").attr('href', psdDownload);
        //All Download
        var urlAllDownload = splitUrl[0]+"/"+splitUrl[1]+"/"+splitUrl[2]+"/"+splitUrl[3]+"/all_icon"+"/"+filenameSplit+".zip";
        // all Downlode
        // $("a.icon-download-box-btn").attr('href', urlAllDownload);
        $("a.icon-download-box-btn").attr('href', download64);
        $("a.icon-download-box-btn").attr('download', download64);
        // icon Name
        $(".icon-popup-title-content").text(iconName);
        // icon src
        $(".icon-popup-image").children("img").attr('src', iconImg);
        $(".icon-popup-iconIndestry").text(iconIndestry);
        $(".icon-content-iconCategary").text(iconCategary);
      })
      //close popup
      $(".closeIconSt").on("click", function(){
        $(".iconPopup").toggle();
      });
      //png toggle dropdown zise 
      $(".icon-download-size").click(function(){
        $(".icon-download-size-box").toggle();
      })
      //png toggle dropdown zise
      $(".icon-download-size-box").click(function(){
        $(this).toggle();
      })
    });
  }

}

import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-ganpati-festival',
  templateUrl: './ganpati-festival.component.html',
  styleUrls: ['./ganpati-festival.component.scss']
})
export class GanpatiFestivalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/ganpati-festival/.test(loc)) {
        $('body').addClass('tech');
      }
    });
  }

}

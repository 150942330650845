<!-- Subscribe -->
<!-- <section class="subscribe scrollingWind">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="subscribe-box-text">
          <h2>Subscribe to newsletter</h2>
          <p>Get new templates report and design news.</p>
          <input type="text" class="subscribe-text" placeholder="Email Address" />
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- Photography  -->
<!-- <section class="photography">
  <a routerLink="/photography" class="pnotographyfooterlink"><img src="assets/img/other_images/photography.jpg" width="100%" /></a>
</section> -->
<!--********************
*Footer
***********************-->
<footer class="footer">
  <div class="footer-section">
    <div class="rocket-cloud">    
    </div>
    <div class="rocket-cloud-2">       
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="footerlogo-box">
            <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2FgrayIcon.svg?alt=media&token=e50753d7-731a-4807-a2cf-ed71987ea63f">
            <div class="footerlogo-belo-tag">Are you ready for more?</div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="footerDetails">
            <h2>View My entire <br>Portfolio projects collection</h2>
          </div>
          <div class="social-icon">
            <a href="#">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.19954 0.46304L6.28051 0.459961C4.12454 0.459961 2.73125 1.88944 2.73125 4.10193V5.78112H0.80175C0.635018 5.78112 0.5 5.9163 0.5 6.08303V8.51599C0.5 8.68272 0.635172 8.81774 0.80175 8.81774H2.73125V14.9569C2.73125 15.1236 2.86627 15.2586 3.033 15.2586H5.55046C5.71719 15.2586 5.85221 15.1234 5.85221 14.9569V8.81774H8.10825C8.27498 8.81774 8.41 8.68272 8.41 8.51599L8.41092 6.08303C8.41092 6.00297 8.37905 5.9263 8.32255 5.86965C8.26605 5.81299 8.18907 5.78112 8.10902 5.78112H5.85221V4.35765C5.85221 3.67347 6.01524 3.32615 6.90648 3.32615L8.19923 3.32569C8.36581 3.32569 8.50083 3.19051 8.50083 3.02393V0.764793C8.50083 0.598367 8.36597 0.463348 8.19954 0.46304Z" fill="#AAC2D4"/>
              </svg>                             
            </a>
            <a href="#">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.92823 15.2586C12.0016 15.2586 15.2987 11.9621 15.2987 7.88819C15.2987 3.82915 12.0154 0.459961 7.92823 0.459961C3.85813 0.459961 0.5 3.81583 0.5 7.88819C0.5 11.979 3.87326 15.2586 7.92823 15.2586ZM4.12402 13.1839C4.66133 11.1362 6.57541 9.47022 9.02363 8.7325C9.59776 10.4263 9.95216 12.2152 10.0598 14.0324C9.3917 14.2647 8.67453 14.3915 7.92823 14.3915C6.51128 14.3915 5.19774 13.9438 4.12402 13.1839ZM10.905 13.6691C10.7757 11.9009 10.4304 10.176 9.87471 8.51832C11.3836 8.21133 12.9567 8.26913 14.386 8.65572C14.1278 10.8417 12.7816 12.699 10.905 13.6691ZM9.08359 6.51121C10.5547 5.96294 12.1145 5.05372 12.9628 3.73985C13.8575 4.84293 14.4034 6.2443 14.4301 7.77122C12.8919 7.38543 11.205 7.34433 9.57788 7.69117C9.42038 7.27986 9.26119 6.90107 9.08359 6.51121ZM12.3482 3.08014C11.6444 4.35326 10.068 5.22929 8.70581 5.72674C7.94336 4.22692 7.01066 2.85874 5.92022 1.64174C6.55339 1.43772 7.22811 1.32707 7.92823 1.32707C9.63343 1.32707 11.1871 1.99298 12.3482 3.08014ZM5.06181 1.98722C6.16093 3.17137 7.10245 4.52758 7.86388 6.00043C5.96222 6.547 3.73201 6.73374 1.52235 6.46796C1.95952 4.49461 3.28773 2.85253 5.06181 1.98722ZM1.3915 7.3257C3.75934 7.6144 6.17527 7.41027 8.24967 6.79031C8.41903 7.15781 8.57777 7.53153 8.72511 7.911C6.34248 8.63325 4.1842 10.2604 3.39781 12.588C2.14728 11.4031 1.36711 9.7343 1.36711 7.88819C1.36711 7.69874 1.37569 7.5112 1.3915 7.3257Z" fill="#AAC2D4"/>
              </svg>                
            </a>
            <a href="#">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.86498 5.7583H0.68335V16.7656H3.86498V5.7583Z" fill="#AAC2D4"/>
                <path d="M14.3067 6.76423C13.6383 5.92374 12.7544 5.50342 11.6554 5.50342C11.2504 5.50342 10.8823 5.5608 10.5513 5.67565C10.2203 5.79041 9.94074 5.95144 9.71249 6.1588C9.48438 6.36616 9.3027 6.55866 9.16782 6.73629C9.03957 6.90502 8.91415 7.10156 8.79177 7.3239V5.75871H5.61963L5.62935 6.29193C5.63587 6.64747 5.63908 7.74333 5.63908 9.57965C5.63908 11.4161 5.63266 13.8115 5.61976 16.7661H8.79177V10.6236C8.79177 10.2461 8.82692 9.94624 8.89777 9.7239C9.03285 9.34607 9.23671 9.02974 9.51004 8.77437C9.78334 8.51869 10.1223 8.39097 10.5274 8.39097C11.08 8.39097 11.4866 8.61124 11.747 9.05183C12.0073 9.49238 12.1374 10.1015 12.1374 10.879V16.7658H15.3094V10.4572C15.3092 8.83537 14.9752 7.60444 14.3067 6.76423Z" fill="#AAC2D4"/>
                <path d="M2.29325 0.459961C1.75983 0.459961 1.32762 0.63967 0.99651 0.998738C0.665436 1.35788 0.5 1.8113 0.5 2.35941C0.5 2.89986 0.660674 3.35172 0.982056 3.71449C1.30334 4.07729 1.72761 4.25879 2.25465 4.25879H2.27393C2.81391 4.25879 3.24943 4.07745 3.5804 3.71449C3.91137 3.35172 4.07363 2.90002 4.06725 2.35941C4.06084 1.81134 3.89682 1.35788 3.57554 0.998738C3.25429 0.639514 2.8267 0.459961 2.29325 0.459961Z" fill="#AAC2D4"/>
              </svg>                                                          
            </a>
            <a href="#">
              <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.07251 14.8788C6.31068 14.8788 5.52653 14.8171 4.73768 14.6935C3.34828 14.4755 1.94182 13.7487 1.54383 13.5311L0.5 12.9604L1.63012 12.5887C2.86548 12.1825 3.61697 11.9305 4.54722 11.5359C3.61576 11.0848 2.89787 10.2734 2.55231 9.22444L2.2894 8.42631L2.50499 8.45924C2.30056 8.25319 2.13913 8.04405 2.01399 7.85386C1.56896 7.17791 1.33334 6.35196 1.38348 5.64415L1.43294 4.9475L1.85001 5.10906C1.67407 4.7768 1.54692 4.42196 1.47219 4.05004C1.29006 3.14304 1.44248 2.17945 1.90149 1.33683L2.2648 0.66989L2.75069 1.2535C4.2874 3.09949 6.23379 4.19454 8.5439 4.5151C8.44954 3.86469 8.52024 3.237 8.75425 2.67435C9.0267 2.01924 9.51138 1.46372 10.1555 1.06775C10.8708 0.628089 11.7383 0.415048 12.5981 0.467871C13.5103 0.52392 14.3386 0.865726 14.9964 1.457C15.3176 1.37353 15.5543 1.28441 15.8742 1.16398C16.0667 1.09154 16.285 1.00928 16.5581 0.915189L17.5646 0.56841L16.9082 2.4429C16.9515 2.43927 16.9961 2.43618 17.0428 2.43416L18.1175 2.38551L17.4823 3.25366C17.4459 3.30339 17.4366 3.31751 17.4237 3.337C17.3725 3.41415 17.3088 3.51012 16.4369 4.67438C16.2186 4.96592 16.1096 5.34563 16.1299 5.74375C16.2071 7.25667 16.0215 8.62551 15.5778 9.81209C15.1582 10.9347 14.5079 11.8979 13.6453 12.6749C12.5778 13.6362 11.2166 14.2944 9.59942 14.631C8.80613 14.796 7.95424 14.8788 7.07251 14.8788Z" fill="#AAC2D4"/>
              </svg>                              
            </a>
          </div>
          <div class="copyright">
            Copyright © 2023 - Annasaheb Kute. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<section class="web-Templateproduct-box">
  <div class="container">
    <div class="row">
      <div class="col-sm-12"> 
        <h3 class="webCatalogtitle">Websites Portfolio</h3>
      </div>
    </div>
    
    <div class="row">
      <!-- SA Technologies, Inc -->
      <div class="col-sm-6"> 
        <div class="webTemplateItems">
          <div class="webTemplate-img">
              <img  src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FWebsites%20Portfolio%2FSA%20Technologies%2C%20Inc%2Fsa-technologies-inc_optimized.jpg?alt=media&token=3b631690-0fe0-4c09-960c-d1ebe4cfc990" width="100%">
          </div>
          <div class="webTemplate-content">
            <h3 class="webTemplateTitle">SA Technologies, Inc</h3>
            <div class="webSubtitle">Design & Integration in WordPress Framework</div>
            <div> 
              <svg width="272" height="28" viewBox="0 0 272 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 1.25H1V25.188H24.875V1.25Z" stroke="#AAC2D4"/>
                <path d="M67.645 1.25H43.77V25.188H67.645V1.25Z" stroke="#AAC2D4"/>
                <path d="M86.687 1.25L88.937 24.375L97.812 26.625L106.937 24.5L109.187 1.25H86.687Z" stroke="#AAC2D4"/>
                <path d="M128.437 1.31006L130.687 24.4361L139.562 26.6861L148.687 24.5611L150.937 1.31006H128.437Z" stroke="#AAC2D4"/>
                <path d="M168.937 1.25L171.187 24.375L180.062 26.625L189.187 24.5L191.437 1.25H168.937Z" stroke="#AAC2D4"/>
                <path d="M5.25 18.967V7.51404H9.146C10.064 7.51404 10.741 7.58704 11.176 7.73304C11.772 7.93104 12.248 8.29804 12.604 8.83504C12.959 9.37204 13.138 10.03 13.138 10.812C13.138 11.843 12.848 12.685 12.269 13.335C11.69 13.986 10.689 14.312 9.267 14.312H6.617V18.968H5.25V18.967ZM6.617 12.959H9.292C10.136 12.959 10.751 12.786 11.142 12.439C11.532 12.093 11.727 11.571 11.727 10.873C11.727 10.42 11.631 10.032 11.438 9.70904C11.246 9.38604 11.014 9.16504 10.741 9.04504C10.469 8.92504 9.977 8.86504 9.266 8.86504H6.616V12.959H6.617Z" fill="#AAC2D4"/>
                <path d="M14.2209 16.4821L15.4859 16.2631C15.5609 16.8491 15.7629 17.2841 16.0949 17.5701C16.4269 17.8561 16.8669 17.9981 17.4159 17.9981C17.9659 17.9981 18.3909 17.8741 18.6909 17.6271C18.9909 17.3801 19.1419 17.0651 19.1419 16.6861C19.1419 16.3581 19.0129 16.1031 18.7549 15.9201C18.5769 15.7951 18.1469 15.6391 17.4639 15.4511C16.4779 15.1761 15.8179 14.9411 15.4849 14.7481C15.1519 14.5561 14.8939 14.2981 14.7109 13.9751C14.5279 13.6521 14.4369 13.2851 14.4369 12.8731C14.4369 12.1651 14.6869 11.5891 15.1859 11.1461C15.6849 10.7041 16.3749 10.4821 17.2589 10.4821C17.8149 10.4821 18.3039 10.5731 18.7259 10.7551C19.1469 10.9381 19.4699 11.1821 19.6929 11.4891C19.9169 11.7971 20.0709 12.2371 20.1569 12.8091L18.9189 12.9971C18.8039 12.0911 18.2619 11.6381 17.2929 11.6381C16.7419 11.6381 16.3319 11.7381 16.0629 11.9381C15.7929 12.1371 15.6589 12.3931 15.6589 12.7041C15.6589 13.0101 15.7879 13.2511 16.0469 13.4281C16.2019 13.5321 16.6609 13.6981 17.4249 13.9261C18.4759 14.2291 19.1559 14.4701 19.4649 14.6501C19.7729 14.8301 20.0129 15.0761 20.1849 15.3861C20.3559 15.6961 20.4419 16.0651 20.4419 16.4921C20.4419 17.2851 20.1649 17.9271 19.6119 18.4181C19.0579 18.9081 18.3019 19.1531 17.3449 19.1531C15.5489 19.1541 14.5079 18.2641 14.2209 16.4821Z" fill="#AAC2D4"/>
                <path d="M48.96 18.467L53.38 7.01404H55.002L59.695 18.467H57.96L56.627 14.999H51.805L50.559 18.467H48.96ZM52.248 13.764H56.152L54.961 10.592C54.592 9.61804 54.322 8.82604 54.151 8.21704C54.003 8.95104 53.794 9.68104 53.523 10.405L52.248 13.764Z" fill="#AAC2D4"/>
                <path d="M60.696 8.63104V7.01404H62.097V8.63104H60.696ZM60.696 18.467V10.17H62.097V18.466H60.696V18.467Z" fill="#AAC2D4"/>
                <path d="M94.6401 12.639L95.3171 7.09204H102.257V7.95904H96.2991L95.8131 11.803C96.5571 11.386 97.4271 11.178 98.4251 11.178C99.6771 11.178 100.675 11.511 101.419 12.178C102.163 12.845 102.534 13.733 102.534 14.842C102.534 16.04 102.172 16.97 101.447 17.631C100.722 18.292 99.7021 18.623 98.3871 18.623C97.1791 18.623 96.2001 18.347 95.4501 17.795C94.7001 17.243 94.2711 16.47 94.1631 15.475H95.2601C95.3681 16.251 95.6891 16.838 96.2231 17.237C96.7571 17.635 97.4781 17.835 98.3871 17.835C99.3781 17.835 100.127 17.577 100.632 17.062C101.137 16.547 101.39 15.812 101.39 14.859C101.39 14.026 101.107 13.346 100.541 12.82C99.9761 12.294 99.2031 12.031 98.2251 12.031C97.6841 12.031 97.2141 12.087 96.8141 12.199C96.4141 12.311 95.9911 12.529 95.5461 12.851L94.6401 12.639Z" fill="#AAC2D4"/>
                <path d="M135.201 15.436L136.602 15.248C136.895 16.753 137.637 17.506 138.828 17.506C139.472 17.506 140.029 17.283 140.5 16.837C140.972 16.391 141.207 15.813 141.207 15.104C141.207 14.436 140.991 13.899 140.559 13.492C140.127 13.085 139.596 12.882 138.967 12.882C138.7 12.882 138.369 12.934 137.976 13.038L138.138 11.804L138.358 11.82C139.053 11.82 139.618 11.646 140.051 11.298C140.486 10.95 140.702 10.485 140.702 9.90199C140.702 9.36699 140.52 8.93699 140.157 8.61199C139.794 8.28699 139.351 8.12499 138.829 8.12499C138.287 8.12499 137.83 8.29599 137.457 8.63699C137.085 8.97799 136.848 9.49499 136.745 10.188L135.344 9.93799C135.522 8.95899 135.922 8.21899 136.545 7.71899C137.168 7.21899 137.921 6.96899 138.805 6.96899C139.733 6.96899 140.521 7.24999 141.169 7.81199C141.817 8.37399 142.142 9.082 142.142 9.936C142.142 10.457 142.004 10.917 141.73 11.318C141.455 11.719 141.071 12.034 140.578 12.263C141.034 12.367 141.41 12.539 141.708 12.778C142.006 13.017 142.246 13.338 142.43 13.738C142.614 14.138 142.705 14.586 142.705 15.08C142.705 16.132 142.327 16.992 141.57 17.661C140.814 18.33 139.908 18.664 138.853 18.664C137.874 18.664 137.044 18.372 136.365 17.789C135.683 17.204 135.295 16.42 135.201 15.436Z" fill="#AAC2D4"/>
                <path d="M173.028 16.1231L174.112 15.9591C174.142 16.7251 174.273 17.2491 174.506 17.5311C174.739 17.8131 175.059 17.9551 175.468 17.9551C175.756 17.9551 176.01 17.8831 176.23 17.7401C176.45 17.5951 176.597 17.4151 176.673 17.1961C176.779 16.9001 176.832 16.4511 176.832 15.8491V8.94507H178.036V15.7741C178.036 17.0041 177.813 17.8731 177.366 18.3781C176.919 18.8831 176.289 19.1371 175.475 19.1371C174.682 19.1371 174.091 18.9091 173.703 18.4531C173.252 17.9351 173.028 17.1571 173.028 16.1231Z" fill="#AAC2D4"/>
                <path d="M179.627 15.74L180.763 15.631C180.824 16.183 180.961 16.611 181.176 16.92C181.391 17.227 181.697 17.475 182.097 17.661C182.495 17.848 182.946 17.941 183.446 17.941C184.163 17.941 184.72 17.783 185.117 17.467C185.513 17.151 185.712 16.74 185.712 16.236C185.712 15.94 185.64 15.682 185.496 15.462C185.351 15.241 185.135 15.061 184.844 14.92C184.554 14.779 183.926 14.58 182.961 14.322C182.103 14.092 181.491 13.861 181.128 13.628C180.764 13.396 180.482 13.101 180.279 12.743C180.076 12.385 179.976 11.981 179.976 11.53C179.976 10.719 180.266 10.056 180.847 9.54104C181.427 9.02604 182.21 8.76904 183.196 8.76904C183.873 8.76904 184.465 8.89004 184.972 9.13104C185.479 9.37204 185.873 9.71904 186.155 10.17C186.435 10.621 186.585 11.152 186.605 11.763L185.446 11.859C185.385 11.207 185.17 10.724 184.799 10.41C184.428 10.096 183.905 9.93804 183.235 9.93804C182.552 9.93804 182.033 10.076 181.673 10.352C181.315 10.628 181.136 10.984 181.136 11.422C181.136 11.827 181.267 12.142 181.53 12.365C181.793 12.589 182.384 12.815 183.302 13.043C184.2 13.268 184.831 13.462 185.196 13.627C185.751 13.883 186.171 14.22 186.454 14.636C186.737 15.052 186.879 15.551 186.879 16.128C186.879 16.702 186.731 17.225 186.433 17.695C186.134 18.167 185.729 18.525 185.216 18.77C184.703 19.016 184.086 19.139 183.364 19.139C182.237 19.139 181.34 18.833 180.67 18.223C180 17.611 179.652 16.784 179.627 15.74Z" fill="#AAC2D4"/>
                <path d="M209 22.0908C209 23.6517 210.27 24.9168 211.837 24.9168H228.163C229.73 24.9168 231 23.6515 231 22.0908V5.82606C231 4.26509 229.73 3 228.163 3H211.837C210.27 3 209 4.26527 209 5.82598V22.0907V22.0908Z" fill="#AAC2D4"/>
                <path d="M218.123 12.6946V9.59093H220.865C221.126 9.59093 221.378 9.61259 221.621 9.656C221.863 9.69932 222.078 9.77671 222.264 9.88826C222.451 9.99982 222.6 10.1547 222.712 10.3529C222.824 10.5512 222.88 10.8052 222.88 11.1148C222.88 11.6724 222.712 12.0751 222.376 12.3229C222.041 12.5708 221.611 12.6946 221.089 12.6946H218.123ZM215.194 7.32349V20.5934H221.649C222.246 20.5934 222.827 20.5191 223.393 20.3704C223.959 20.2217 224.463 19.9925 224.904 19.6827C225.346 19.373 225.697 18.9734 225.958 18.484C226.22 17.9946 226.35 17.4153 226.35 16.7462C226.35 15.9161 226.148 15.2068 225.744 14.6183C225.34 14.0297 224.727 13.6177 223.906 13.3823C224.503 13.0974 224.954 12.7318 225.259 12.2858C225.563 11.8397 225.716 11.2822 225.716 10.6131C225.716 9.99357 225.613 9.47321 225.408 9.05192C225.203 8.6307 224.914 8.29305 224.54 8.03903C224.167 7.78502 223.72 7.60224 223.197 7.49077C222.675 7.37922 222.097 7.32349 221.462 7.32349H215.194ZM218.123 18.326V14.6832H221.313C221.947 14.6832 222.457 14.8288 222.843 15.12C223.228 15.4112 223.421 15.8975 223.421 16.579C223.421 16.9259 223.362 17.2109 223.244 17.4339C223.126 17.6569 222.967 17.8335 222.768 17.9636C222.569 18.0937 222.339 18.1866 222.078 18.2424C221.817 18.2981 221.543 18.326 221.257 18.326H218.123Z" fill="white"/>
                <path d="M249 13.4565C249 17.9909 251.645 21.9104 255.482 23.7671L249.996 8.79416C249.358 10.2189 249 11.7956 249 13.4565ZM268.263 12.8786C268.263 11.4622 267.753 10.4821 267.315 9.71911C266.732 8.77491 266.186 7.97637 266.186 7.03209C266.186 5.97897 266.987 4.9988 268.117 4.9988C268.168 4.9988 268.217 5.00499 268.266 5.00812C266.22 3.14049 263.494 2 260.5 2C256.482 2 252.948 4.05384 250.891 7.16346C251.162 7.17232 251.416 7.17782 251.632 7.17782C252.834 7.17782 254.697 7.03217 254.697 7.03217C255.317 6.99581 255.389 7.90335 254.77 7.97637C254.77 7.97637 254.147 8.049 253.454 8.08498L257.642 20.4949L260.159 12.9756L258.367 8.0849C257.747 8.04893 257.161 7.97629 257.161 7.97629C256.541 7.93963 256.614 6.99573 257.234 7.03209C257.234 7.03209 259.133 7.17775 260.263 7.17775C261.465 7.17775 263.328 7.03209 263.328 7.03209C263.948 6.99573 264.021 7.90327 263.401 7.97629C263.401 7.97629 262.777 8.04893 262.085 8.0849L266.241 20.4001L267.388 16.5824C267.971 15.0935 268.263 13.8591 268.263 12.8786ZM260.702 14.4587L257.251 24.4471C258.282 24.7489 259.371 24.9138 260.5 24.9138C261.839 24.9138 263.124 24.6835 264.319 24.2643C264.289 24.2152 264.26 24.1632 264.237 24.1064L260.702 14.4587ZM270.592 7.96048C270.641 8.32511 270.669 8.71648 270.669 9.1384C270.669 10.3005 270.45 11.6074 269.794 13.2417L266.282 23.3588C269.701 21.373 272 17.683 272 13.4566C272 11.4645 271.489 9.59194 270.592 7.96048Z" fill="#AAC2D4"/>
                <path d="M34.77 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M77.104 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M118.921 0.874023V25.564" stroke="#AAC2D4"/>
                <path d="M159.771 1.30908V26.0001" stroke="#AAC2D4"/>
                <path d="M199.104 1.65198V26.344" stroke="#AAC2D4"/>
                <path d="M239 1.65198V26.344" stroke="#AAC2D4"/>
              </svg>                                 
            </div>
          </div>
        </div>
      </div>
      <!-- Mindpool Technologies Limited -->
      <div class="col-sm-6"> 
        <div class="webTemplateItems">
          <div class="webTemplate-img">
            <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FWebsites%20Portfolio%2FMindpool%2Fmindpool_optimized.jpg?alt=media&token=1f28e7e7-f11c-449e-98e9-2ff9761bd922" width="100%">
          </div>
          <div class="webTemplate-content">
            <h3 class="webTemplateTitle">Mindpool Technologies Limited</h3>
            <div class="webSubtitle">Design & Integration in WordPress Framework</div>
            <div> 
              <svg width="272" height="28" viewBox="0 0 272 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 1.25H1V25.188H24.875V1.25Z" stroke="#AAC2D4"/>
                <path d="M67.645 1.25H43.77V25.188H67.645V1.25Z" stroke="#AAC2D4"/>
                <path d="M86.687 1.25L88.937 24.375L97.812 26.625L106.937 24.5L109.187 1.25H86.687Z" stroke="#AAC2D4"/>
                <path d="M128.437 1.31006L130.687 24.4361L139.562 26.6861L148.687 24.5611L150.937 1.31006H128.437Z" stroke="#AAC2D4"/>
                <path d="M168.937 1.25L171.187 24.375L180.062 26.625L189.187 24.5L191.437 1.25H168.937Z" stroke="#AAC2D4"/>
                <path d="M5.25 18.967V7.51404H9.146C10.064 7.51404 10.741 7.58704 11.176 7.73304C11.772 7.93104 12.248 8.29804 12.604 8.83504C12.959 9.37204 13.138 10.03 13.138 10.812C13.138 11.843 12.848 12.685 12.269 13.335C11.69 13.986 10.689 14.312 9.267 14.312H6.617V18.968H5.25V18.967ZM6.617 12.959H9.292C10.136 12.959 10.751 12.786 11.142 12.439C11.532 12.093 11.727 11.571 11.727 10.873C11.727 10.42 11.631 10.032 11.438 9.70904C11.246 9.38604 11.014 9.16504 10.741 9.04504C10.469 8.92504 9.977 8.86504 9.266 8.86504H6.616V12.959H6.617Z" fill="#AAC2D4"/>
                <path d="M14.2212 16.4821L15.4862 16.2631C15.5612 16.8491 15.7632 17.2841 16.0952 17.5701C16.4272 17.8561 16.8672 17.9981 17.4162 17.9981C17.9662 17.9981 18.3912 17.8741 18.6912 17.6271C18.9912 17.3801 19.1422 17.0651 19.1422 16.6861C19.1422 16.3581 19.0132 16.1031 18.7552 15.9201C18.5772 15.7951 18.1472 15.6391 17.4642 15.4511C16.4782 15.1761 15.8182 14.9411 15.4852 14.7481C15.1522 14.5561 14.8942 14.2981 14.7112 13.9751C14.5282 13.6521 14.4372 13.2851 14.4372 12.8731C14.4372 12.1651 14.6872 11.5891 15.1862 11.1461C15.6852 10.7041 16.3752 10.4821 17.2592 10.4821C17.8152 10.4821 18.3042 10.5731 18.7262 10.7551C19.1472 10.9381 19.4702 11.1821 19.6932 11.4891C19.9172 11.7971 20.0712 12.2371 20.1572 12.8091L18.9192 12.9971C18.8042 12.0911 18.2622 11.6381 17.2932 11.6381C16.7422 11.6381 16.3322 11.7381 16.0632 11.9381C15.7932 12.1371 15.6592 12.3931 15.6592 12.7041C15.6592 13.0101 15.7882 13.2511 16.0472 13.4281C16.2022 13.5321 16.6612 13.6981 17.4252 13.9261C18.4762 14.2291 19.1562 14.4701 19.4652 14.6501C19.7732 14.8301 20.0132 15.0761 20.1852 15.3861C20.3562 15.6961 20.4422 16.0651 20.4422 16.4921C20.4422 17.2851 20.1652 17.9271 19.6122 18.4181C19.0582 18.9081 18.3022 19.1531 17.3452 19.1531C15.5492 19.1541 14.5082 18.2641 14.2212 16.4821Z" fill="#AAC2D4"/>
                <path d="M48.96 18.467L53.38 7.01404H55.002L59.695 18.467H57.96L56.627 14.999H51.805L50.559 18.467H48.96ZM52.248 13.764H56.152L54.961 10.592C54.592 9.61804 54.322 8.82604 54.151 8.21704C54.003 8.95104 53.794 9.68104 53.523 10.405L52.248 13.764Z" fill="#AAC2D4"/>
                <path d="M60.6958 8.63104V7.01404H62.0968V8.63104H60.6958ZM60.6958 18.467V10.17H62.0968V18.466H60.6958V18.467Z" fill="#AAC2D4"/>
                <path d="M94.6401 12.639L95.3171 7.09204H102.257V7.95904H96.2991L95.8131 11.803C96.5571 11.386 97.4271 11.178 98.4251 11.178C99.6771 11.178 100.675 11.511 101.419 12.178C102.163 12.845 102.534 13.733 102.534 14.842C102.534 16.04 102.172 16.97 101.447 17.631C100.722 18.292 99.7021 18.623 98.3871 18.623C97.1791 18.623 96.2001 18.347 95.4501 17.795C94.7001 17.243 94.2711 16.47 94.1631 15.475H95.2601C95.3681 16.251 95.6891 16.838 96.2231 17.237C96.7571 17.635 97.4781 17.835 98.3871 17.835C99.3781 17.835 100.127 17.577 100.632 17.062C101.137 16.547 101.39 15.812 101.39 14.859C101.39 14.026 101.107 13.346 100.541 12.82C99.9761 12.294 99.2031 12.031 98.2251 12.031C97.6841 12.031 97.2141 12.087 96.8141 12.199C96.4141 12.311 95.9911 12.529 95.5461 12.851L94.6401 12.639Z" fill="#AAC2D4"/>
                <path d="M135.201 15.436L136.602 15.248C136.895 16.753 137.637 17.506 138.828 17.506C139.472 17.506 140.029 17.283 140.5 16.837C140.972 16.391 141.207 15.813 141.207 15.104C141.207 14.436 140.991 13.899 140.559 13.492C140.127 13.085 139.596 12.882 138.967 12.882C138.7 12.882 138.369 12.934 137.976 13.038L138.138 11.804L138.358 11.82C139.053 11.82 139.618 11.646 140.051 11.298C140.486 10.95 140.702 10.485 140.702 9.90199C140.702 9.36699 140.52 8.93699 140.157 8.61199C139.794 8.28699 139.351 8.12499 138.829 8.12499C138.287 8.12499 137.83 8.29599 137.457 8.63699C137.085 8.97799 136.848 9.49499 136.745 10.188L135.344 9.93799C135.522 8.95899 135.922 8.21899 136.545 7.71899C137.168 7.21899 137.921 6.96899 138.805 6.96899C139.733 6.96899 140.521 7.24999 141.169 7.81199C141.817 8.37399 142.142 9.082 142.142 9.936C142.142 10.457 142.004 10.917 141.73 11.318C141.455 11.719 141.071 12.034 140.578 12.263C141.034 12.367 141.41 12.539 141.708 12.778C142.006 13.017 142.246 13.338 142.43 13.738C142.614 14.138 142.705 14.586 142.705 15.08C142.705 16.132 142.327 16.992 141.57 17.661C140.814 18.33 139.908 18.664 138.853 18.664C137.874 18.664 137.044 18.372 136.365 17.789C135.683 17.204 135.295 16.42 135.201 15.436Z" fill="#AAC2D4"/>
                <path d="M173.028 16.1231L174.112 15.9591C174.142 16.7251 174.273 17.2491 174.506 17.5311C174.739 17.8131 175.059 17.9551 175.468 17.9551C175.756 17.9551 176.01 17.8831 176.23 17.7401C176.45 17.5951 176.597 17.4151 176.673 17.1961C176.779 16.9001 176.832 16.4511 176.832 15.8491V8.94507H178.036V15.7741C178.036 17.0041 177.813 17.8731 177.366 18.3781C176.919 18.8831 176.289 19.1371 175.475 19.1371C174.682 19.1371 174.091 18.9091 173.703 18.4531C173.252 17.9351 173.028 17.1571 173.028 16.1231Z" fill="#AAC2D4"/>
                <path d="M179.627 15.74L180.763 15.631C180.824 16.183 180.961 16.611 181.176 16.92C181.391 17.227 181.697 17.475 182.097 17.661C182.495 17.848 182.946 17.941 183.446 17.941C184.163 17.941 184.72 17.783 185.117 17.467C185.513 17.151 185.712 16.74 185.712 16.236C185.712 15.94 185.64 15.682 185.496 15.462C185.351 15.241 185.135 15.061 184.844 14.92C184.554 14.779 183.926 14.58 182.961 14.322C182.103 14.092 181.491 13.861 181.128 13.628C180.764 13.396 180.482 13.101 180.279 12.743C180.076 12.385 179.976 11.981 179.976 11.53C179.976 10.719 180.266 10.056 180.847 9.54104C181.427 9.02604 182.21 8.76904 183.196 8.76904C183.873 8.76904 184.465 8.89004 184.972 9.13104C185.479 9.37204 185.873 9.71904 186.155 10.17C186.435 10.621 186.585 11.152 186.605 11.763L185.446 11.859C185.385 11.207 185.17 10.724 184.799 10.41C184.428 10.096 183.905 9.93804 183.235 9.93804C182.552 9.93804 182.033 10.076 181.673 10.352C181.315 10.628 181.136 10.984 181.136 11.422C181.136 11.827 181.267 12.142 181.53 12.365C181.793 12.589 182.384 12.815 183.302 13.043C184.2 13.268 184.831 13.462 185.196 13.627C185.751 13.883 186.171 14.22 186.454 14.636C186.737 15.052 186.879 15.551 186.879 16.128C186.879 16.702 186.731 17.225 186.433 17.695C186.134 18.167 185.729 18.525 185.216 18.77C184.703 19.016 184.086 19.139 183.364 19.139C182.237 19.139 181.34 18.833 180.67 18.223C180 17.611 179.652 16.784 179.627 15.74Z" fill="#AAC2D4"/>
                <path d="M209 22.0908C209 23.6517 210.27 24.9168 211.837 24.9168H228.163C229.73 24.9168 231 23.6515 231 22.0908V5.82606C231 4.26509 229.73 3 228.163 3H211.837C210.27 3 209 4.26527 209 5.82598V22.0907V22.0908Z" fill="#AAC2D4"/>
                <path d="M218.123 12.6946V9.59093H220.865C221.126 9.59093 221.378 9.61259 221.621 9.656C221.863 9.69932 222.078 9.77671 222.264 9.88826C222.451 9.99982 222.6 10.1547 222.712 10.3529C222.824 10.5512 222.88 10.8052 222.88 11.1148C222.88 11.6724 222.712 12.0751 222.376 12.3229C222.041 12.5708 221.611 12.6946 221.089 12.6946H218.123ZM215.194 7.32349V20.5934H221.649C222.246 20.5934 222.827 20.5191 223.393 20.3704C223.959 20.2217 224.463 19.9925 224.904 19.6827C225.346 19.373 225.697 18.9734 225.958 18.484C226.22 17.9946 226.35 17.4153 226.35 16.7462C226.35 15.9161 226.148 15.2068 225.744 14.6183C225.34 14.0297 224.727 13.6177 223.906 13.3823C224.503 13.0974 224.954 12.7318 225.259 12.2858C225.563 11.8397 225.716 11.2822 225.716 10.6131C225.716 9.99357 225.613 9.47321 225.408 9.05192C225.203 8.6307 224.914 8.29305 224.54 8.03903C224.167 7.78502 223.72 7.60224 223.197 7.49077C222.675 7.37922 222.097 7.32349 221.462 7.32349H215.194ZM218.123 18.326V14.6832H221.313C221.947 14.6832 222.457 14.8288 222.843 15.12C223.228 15.4112 223.421 15.8975 223.421 16.579C223.421 16.9259 223.362 17.2109 223.244 17.4339C223.126 17.6569 222.967 17.8335 222.768 17.9636C222.569 18.0937 222.339 18.1866 222.078 18.2424C221.817 18.2981 221.543 18.326 221.257 18.326H218.123Z" fill="white"/>
                <path d="M249 13.4565C249 17.9909 251.645 21.9104 255.482 23.7671L249.996 8.79416C249.358 10.2189 249 11.7956 249 13.4565ZM268.263 12.8786C268.263 11.4622 267.753 10.4821 267.315 9.71911C266.732 8.77491 266.186 7.97637 266.186 7.03209C266.186 5.97897 266.987 4.9988 268.117 4.9988C268.168 4.9988 268.217 5.00499 268.266 5.00812C266.22 3.14049 263.494 2 260.5 2C256.482 2 252.948 4.05384 250.891 7.16346C251.162 7.17232 251.416 7.17782 251.632 7.17782C252.834 7.17782 254.697 7.03217 254.697 7.03217C255.317 6.99581 255.389 7.90335 254.77 7.97637C254.77 7.97637 254.147 8.049 253.454 8.08498L257.642 20.4949L260.159 12.9756L258.367 8.0849C257.747 8.04893 257.161 7.97629 257.161 7.97629C256.541 7.93963 256.614 6.99573 257.234 7.03209C257.234 7.03209 259.133 7.17775 260.263 7.17775C261.465 7.17775 263.328 7.03209 263.328 7.03209C263.948 6.99573 264.021 7.90327 263.401 7.97629C263.401 7.97629 262.777 8.04893 262.085 8.0849L266.241 20.4001L267.388 16.5824C267.971 15.0935 268.263 13.8591 268.263 12.8786ZM260.702 14.4587L257.251 24.4471C258.282 24.7489 259.371 24.9138 260.5 24.9138C261.839 24.9138 263.124 24.6835 264.319 24.2643C264.289 24.2152 264.26 24.1632 264.237 24.1064L260.702 14.4587ZM270.592 7.96048C270.641 8.32511 270.669 8.71648 270.669 9.1384C270.669 10.3005 270.45 11.6074 269.794 13.2417L266.282 23.3588C269.701 21.373 272 17.683 272 13.4566C272 11.4645 271.489 9.59194 270.592 7.96048Z" fill="#AAC2D4"/>
                <path d="M34.77 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M77.104 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M118.921 0.874023V25.564" stroke="#AAC2D4"/>
                <path d="M159.771 1.30908V26.0001" stroke="#AAC2D4"/>
                <path d="M199.104 1.65198V26.344" stroke="#AAC2D4"/>
                <path d="M239 1.65198V26.344" stroke="#AAC2D4"/>
              </svg>                                                                
            </div>
          </div>
        </div>
      </div>
      <!-- Indus Health Plus (P) Ltd. -->
      <div class="col-sm-6"> 
        <div class="webTemplateItems">
          <div class="webTemplate-img">
            <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FWebsites%20Portfolio%2FBarjeel%20Aerial%20Photography%20Services%2FBarjeel%20Aerial%20Photography%20Services_optimized.jpg?alt=media&token=f3e7bb5c-8ffb-4f27-9dcb-a767bf10865f" width="100%">
          </div>
          <div class="webTemplate-content">
            <h3 class="webTemplateTitle">BARJEEL AERIAL PHOTOGRAPHY SERVICES</h3>
            <div class="webSubtitle">Design & Integration in WordPress Framework</div>
            <div> 
              <svg width="272" height="28" viewBox="0 0 272 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 1.25H1V25.188H24.875V1.25Z" stroke="#AAC2D4"/>
                <path d="M67.645 1.25H43.77V25.188H67.645V1.25Z" stroke="#AAC2D4"/>
                <path d="M86.687 1.25L88.937 24.375L97.812 26.625L106.937 24.5L109.187 1.25H86.687Z" stroke="#AAC2D4"/>
                <path d="M128.437 1.31006L130.687 24.4361L139.562 26.6861L148.687 24.5611L150.937 1.31006H128.437Z" stroke="#AAC2D4"/>
                <path d="M168.937 1.25L171.187 24.375L180.062 26.625L189.187 24.5L191.437 1.25H168.937Z" stroke="#AAC2D4"/>
                <path d="M5.25 18.9669V7.51392H9.146C10.064 7.51392 10.741 7.58692 11.176 7.73292C11.772 7.93092 12.248 8.29792 12.604 8.83492C12.959 9.37192 13.138 10.0299 13.138 10.8119C13.138 11.8429 12.848 12.6849 12.269 13.3349C11.69 13.9859 10.689 14.3119 9.267 14.3119H6.617V18.9679H5.25V18.9669ZM6.617 12.9589H9.292C10.136 12.9589 10.751 12.7859 11.142 12.4389C11.532 12.0929 11.727 11.5709 11.727 10.8729C11.727 10.4199 11.631 10.0319 11.438 9.70892C11.246 9.38592 11.014 9.16492 10.741 9.04492C10.469 8.92492 9.977 8.86492 9.266 8.86492H6.616V12.9589H6.617Z" fill="#AAC2D4"/>
                <path d="M14.2209 16.4819L15.4859 16.2629C15.5609 16.8489 15.7629 17.2839 16.0949 17.5699C16.4269 17.8559 16.8669 17.9979 17.4159 17.9979C17.9659 17.9979 18.3909 17.8739 18.6909 17.6269C18.9909 17.3799 19.1419 17.0649 19.1419 16.6859C19.1419 16.3579 19.0129 16.1029 18.7549 15.9199C18.5769 15.7949 18.1469 15.6389 17.4639 15.4509C16.4779 15.1759 15.8179 14.9409 15.4849 14.7479C15.1519 14.5559 14.8939 14.2979 14.7109 13.9749C14.5279 13.6519 14.4369 13.2849 14.4369 12.8729C14.4369 12.1649 14.6869 11.5889 15.1859 11.1459C15.6849 10.7039 16.3749 10.4819 17.2589 10.4819C17.8149 10.4819 18.3039 10.5729 18.7259 10.7549C19.1469 10.9379 19.4699 11.1819 19.6929 11.4889C19.9169 11.7969 20.0709 12.2369 20.1569 12.8089L18.9189 12.9969C18.8039 12.0909 18.2619 11.6379 17.2929 11.6379C16.7419 11.6379 16.3319 11.7379 16.0629 11.9379C15.7929 12.1369 15.6589 12.3929 15.6589 12.7039C15.6589 13.0099 15.7879 13.2509 16.0469 13.4279C16.2019 13.5319 16.6609 13.6979 17.4249 13.9259C18.4759 14.2289 19.1559 14.4699 19.4649 14.6499C19.7729 14.8299 20.0129 15.0759 20.1849 15.3859C20.3559 15.6959 20.4419 16.0649 20.4419 16.4919C20.4419 17.2849 20.1649 17.9269 19.6119 18.4179C19.0579 18.9079 18.3019 19.1529 17.3449 19.1529C15.5489 19.1539 14.5079 18.2639 14.2209 16.4819Z" fill="#AAC2D4"/>
                <path d="M48.96 18.4669L53.38 7.01392H55.002L59.695 18.4669H57.96L56.627 14.9989H51.805L50.559 18.4669H48.96ZM52.248 13.7639H56.152L54.961 10.5919C54.592 9.61792 54.322 8.82592 54.151 8.21692C54.003 8.95092 53.794 9.68092 53.523 10.4049L52.248 13.7639Z" fill="#AAC2D4"/>
                <path d="M60.696 8.63092V7.01392H62.097V8.63092H60.696ZM60.696 18.4669V10.1699H62.097V18.4659H60.696V18.4669Z" fill="#AAC2D4"/>
                <path d="M94.6401 12.639L95.3171 7.09204H102.257V7.95904H96.2991L95.8131 11.803C96.5571 11.386 97.4271 11.178 98.4251 11.178C99.6771 11.178 100.675 11.511 101.419 12.178C102.163 12.845 102.534 13.733 102.534 14.842C102.534 16.04 102.172 16.97 101.447 17.631C100.722 18.292 99.7021 18.623 98.3871 18.623C97.1791 18.623 96.2001 18.347 95.4501 17.795C94.7001 17.243 94.2711 16.47 94.1631 15.475H95.2601C95.3681 16.251 95.6891 16.838 96.2231 17.237C96.7571 17.635 97.4781 17.835 98.3871 17.835C99.3781 17.835 100.127 17.577 100.632 17.062C101.137 16.547 101.39 15.812 101.39 14.859C101.39 14.026 101.107 13.346 100.541 12.82C99.9761 12.294 99.2031 12.031 98.2251 12.031C97.6841 12.031 97.2141 12.087 96.8141 12.199C96.4141 12.311 95.9911 12.529 95.5461 12.851L94.6401 12.639Z" fill="#AAC2D4"/>
                <path d="M135.201 15.436L136.602 15.248C136.895 16.753 137.637 17.506 138.828 17.506C139.472 17.506 140.029 17.283 140.5 16.837C140.972 16.391 141.207 15.813 141.207 15.104C141.207 14.436 140.991 13.899 140.559 13.492C140.127 13.085 139.596 12.882 138.967 12.882C138.7 12.882 138.369 12.934 137.976 13.038L138.138 11.804L138.358 11.82C139.053 11.82 139.618 11.646 140.051 11.298C140.486 10.95 140.702 10.485 140.702 9.90199C140.702 9.36699 140.52 8.93699 140.157 8.61199C139.794 8.28699 139.351 8.12499 138.829 8.12499C138.287 8.12499 137.83 8.29599 137.457 8.63699C137.085 8.97799 136.848 9.49499 136.745 10.188L135.344 9.93799C135.522 8.95899 135.922 8.21899 136.545 7.71899C137.168 7.21899 137.921 6.96899 138.805 6.96899C139.733 6.96899 140.521 7.24999 141.169 7.81199C141.817 8.37399 142.142 9.082 142.142 9.936C142.142 10.457 142.004 10.917 141.73 11.318C141.455 11.719 141.071 12.034 140.578 12.263C141.034 12.367 141.41 12.539 141.708 12.778C142.006 13.017 142.246 13.338 142.43 13.738C142.614 14.138 142.705 14.586 142.705 15.08C142.705 16.132 142.327 16.992 141.57 17.661C140.814 18.33 139.908 18.664 138.853 18.664C137.874 18.664 137.044 18.372 136.365 17.789C135.683 17.204 135.295 16.42 135.201 15.436Z" fill="#AAC2D4"/>
                <path d="M173.028 16.1231L174.112 15.9591C174.142 16.7251 174.273 17.2491 174.506 17.5311C174.739 17.8131 175.059 17.9551 175.468 17.9551C175.756 17.9551 176.01 17.8831 176.23 17.7401C176.45 17.5951 176.597 17.4151 176.673 17.1961C176.779 16.9001 176.832 16.4511 176.832 15.8491V8.94507H178.036V15.7741C178.036 17.0041 177.813 17.8731 177.366 18.3781C176.919 18.8831 176.289 19.1371 175.475 19.1371C174.682 19.1371 174.091 18.9091 173.703 18.4531C173.252 17.9351 173.028 17.1571 173.028 16.1231Z" fill="#AAC2D4"/>
                <path d="M179.627 15.74L180.763 15.631C180.824 16.183 180.961 16.611 181.176 16.92C181.391 17.227 181.697 17.475 182.097 17.661C182.495 17.848 182.946 17.941 183.446 17.941C184.163 17.941 184.72 17.783 185.117 17.467C185.513 17.151 185.712 16.74 185.712 16.236C185.712 15.94 185.64 15.682 185.496 15.462C185.351 15.241 185.135 15.061 184.844 14.92C184.554 14.779 183.926 14.58 182.961 14.322C182.103 14.092 181.491 13.861 181.128 13.628C180.764 13.396 180.482 13.101 180.279 12.743C180.076 12.385 179.976 11.981 179.976 11.53C179.976 10.719 180.266 10.056 180.847 9.54104C181.427 9.02604 182.21 8.76904 183.196 8.76904C183.873 8.76904 184.465 8.89004 184.972 9.13104C185.479 9.37204 185.873 9.71904 186.155 10.17C186.435 10.621 186.585 11.152 186.605 11.763L185.446 11.859C185.385 11.207 185.17 10.724 184.799 10.41C184.428 10.096 183.905 9.93804 183.235 9.93804C182.552 9.93804 182.033 10.076 181.673 10.352C181.315 10.628 181.136 10.984 181.136 11.422C181.136 11.827 181.267 12.142 181.53 12.365C181.793 12.589 182.384 12.815 183.302 13.043C184.2 13.268 184.831 13.462 185.196 13.627C185.751 13.883 186.171 14.22 186.454 14.636C186.737 15.052 186.879 15.551 186.879 16.128C186.879 16.702 186.731 17.225 186.433 17.695C186.134 18.167 185.729 18.525 185.216 18.77C184.703 19.016 184.086 19.139 183.364 19.139C182.237 19.139 181.34 18.833 180.67 18.223C180 17.611 179.652 16.784 179.627 15.74Z" fill="#AAC2D4"/>
                <path d="M209 22.0908C209 23.6517 210.27 24.9168 211.837 24.9168H228.163C229.73 24.9168 231 23.6515 231 22.0908V5.82606C231 4.26509 229.73 3 228.163 3H211.837C210.27 3 209 4.26527 209 5.82598V22.0907V22.0908Z" fill="#AAC2D4"/>
                <path d="M218.123 12.6946V9.59093H220.865C221.126 9.59093 221.378 9.61259 221.621 9.656C221.863 9.69932 222.078 9.77671 222.264 9.88826C222.451 9.99982 222.6 10.1547 222.712 10.3529C222.824 10.5512 222.88 10.8052 222.88 11.1148C222.88 11.6724 222.712 12.0751 222.376 12.3229C222.041 12.5708 221.611 12.6946 221.089 12.6946H218.123ZM215.194 7.32349V20.5934H221.649C222.246 20.5934 222.827 20.5191 223.393 20.3704C223.959 20.2217 224.463 19.9925 224.904 19.6827C225.346 19.373 225.697 18.9734 225.958 18.484C226.22 17.9946 226.35 17.4153 226.35 16.7462C226.35 15.9161 226.148 15.2068 225.744 14.6183C225.34 14.0297 224.727 13.6177 223.906 13.3823C224.503 13.0974 224.954 12.7318 225.259 12.2858C225.563 11.8397 225.716 11.2822 225.716 10.6131C225.716 9.99357 225.613 9.47321 225.408 9.05192C225.203 8.6307 224.914 8.29305 224.54 8.03903C224.167 7.78502 223.72 7.60224 223.197 7.49077C222.675 7.37922 222.097 7.32349 221.462 7.32349H215.194ZM218.123 18.326V14.6832H221.313C221.947 14.6832 222.457 14.8288 222.843 15.12C223.228 15.4112 223.421 15.8975 223.421 16.579C223.421 16.9259 223.362 17.2109 223.244 17.4339C223.126 17.6569 222.967 17.8335 222.768 17.9636C222.569 18.0937 222.339 18.1866 222.078 18.2424C221.817 18.2981 221.543 18.326 221.257 18.326H218.123Z" fill="white"/>
                <path d="M249 13.4565C249 17.9909 251.645 21.9104 255.482 23.7671L249.996 8.79416C249.358 10.2189 249 11.7956 249 13.4565ZM268.263 12.8786C268.263 11.4622 267.753 10.4821 267.315 9.71911C266.732 8.77491 266.186 7.97637 266.186 7.03209C266.186 5.97897 266.987 4.9988 268.117 4.9988C268.168 4.9988 268.217 5.00499 268.266 5.00812C266.22 3.14049 263.494 2 260.5 2C256.482 2 252.948 4.05384 250.891 7.16346C251.162 7.17232 251.416 7.17782 251.632 7.17782C252.834 7.17782 254.697 7.03217 254.697 7.03217C255.317 6.99581 255.389 7.90335 254.77 7.97637C254.77 7.97637 254.147 8.049 253.454 8.08498L257.642 20.4949L260.159 12.9756L258.367 8.0849C257.747 8.04893 257.161 7.97629 257.161 7.97629C256.541 7.93963 256.614 6.99573 257.234 7.03209C257.234 7.03209 259.133 7.17775 260.263 7.17775C261.465 7.17775 263.328 7.03209 263.328 7.03209C263.948 6.99573 264.021 7.90327 263.401 7.97629C263.401 7.97629 262.777 8.04893 262.085 8.0849L266.241 20.4001L267.388 16.5824C267.971 15.0935 268.263 13.8591 268.263 12.8786ZM260.702 14.4587L257.251 24.4471C258.282 24.7489 259.371 24.9138 260.5 24.9138C261.839 24.9138 263.124 24.6835 264.319 24.2643C264.289 24.2152 264.26 24.1632 264.237 24.1064L260.702 14.4587ZM270.592 7.96048C270.641 8.32511 270.669 8.71648 270.669 9.1384C270.669 10.3005 270.45 11.6074 269.794 13.2417L266.282 23.3588C269.701 21.373 272 17.683 272 13.4566C272 11.4645 271.489 9.59194 270.592 7.96048Z" fill="#AAC2D4"/>
                <path d="M34.77 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M77.104 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M118.921 0.874023V25.564" stroke="#AAC2D4"/>
                <path d="M159.771 1.30908V26.0001" stroke="#AAC2D4"/>
                <path d="M199.104 1.65186V26.3439" stroke="#AAC2D4"/>
                <path d="M239 1.65186V26.3439" stroke="#AAC2D4"/>
                </svg>
                                  
            </div>
          </div>
        </div>
      </div>
      <!-- Ketsaal -->
      <div class="col-sm-6"> 
        <div class="webTemplateItems">
          <div class="webTemplate-img">
            <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FWebsites%20Portfolio%2FWeten%2Fweten_optimized.jpg?alt=media&token=333fef7f-0107-4f2a-8994-d103b4bb1507" width="100%">
          </div>
          <div class="webTemplate-content">
            <h3 class="webTemplateTitle">Weten</h3>
            <div class="webSubtitle">Design & Integration in WordPress Framework</div>
            <div> 
              <svg width="272" height="28" viewBox="0 0 272 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 1.25H1V25.188H24.875V1.25Z" stroke="#AAC2D4"/>
                <path d="M67.645 1.25H43.77V25.188H67.645V1.25Z" stroke="#AAC2D4"/>
                <path d="M86.687 1.25L88.937 24.375L97.812 26.625L106.937 24.5L109.187 1.25H86.687Z" stroke="#AAC2D4"/>
                <path d="M128.437 1.31006L130.687 24.4361L139.562 26.6861L148.687 24.5611L150.937 1.31006H128.437Z" stroke="#AAC2D4"/>
                <path d="M168.937 1.25L171.187 24.375L180.062 26.625L189.187 24.5L191.437 1.25H168.937Z" stroke="#AAC2D4"/>
                <path d="M5.25 18.9669V7.51392H9.146C10.064 7.51392 10.741 7.58692 11.176 7.73292C11.772 7.93092 12.248 8.29792 12.604 8.83492C12.959 9.37192 13.138 10.0299 13.138 10.8119C13.138 11.8429 12.848 12.6849 12.269 13.3349C11.69 13.9859 10.689 14.3119 9.267 14.3119H6.617V18.9679H5.25V18.9669ZM6.617 12.9589H9.292C10.136 12.9589 10.751 12.7859 11.142 12.4389C11.532 12.0929 11.727 11.5709 11.727 10.8729C11.727 10.4199 11.631 10.0319 11.438 9.70892C11.246 9.38592 11.014 9.16492 10.741 9.04492C10.469 8.92492 9.977 8.86492 9.266 8.86492H6.616V12.9589H6.617Z" fill="#AAC2D4"/>
                <path d="M14.2212 16.4819L15.4862 16.2629C15.5612 16.8489 15.7632 17.2839 16.0952 17.5699C16.4272 17.8559 16.8672 17.9979 17.4162 17.9979C17.9662 17.9979 18.3912 17.8739 18.6912 17.6269C18.9912 17.3799 19.1422 17.0649 19.1422 16.6859C19.1422 16.3579 19.0132 16.1029 18.7552 15.9199C18.5772 15.7949 18.1472 15.6389 17.4642 15.4509C16.4782 15.1759 15.8182 14.9409 15.4852 14.7479C15.1522 14.5559 14.8942 14.2979 14.7112 13.9749C14.5282 13.6519 14.4372 13.2849 14.4372 12.8729C14.4372 12.1649 14.6872 11.5889 15.1862 11.1459C15.6852 10.7039 16.3752 10.4819 17.2592 10.4819C17.8152 10.4819 18.3042 10.5729 18.7262 10.7549C19.1472 10.9379 19.4702 11.1819 19.6932 11.4889C19.9172 11.7969 20.0712 12.2369 20.1572 12.8089L18.9192 12.9969C18.8042 12.0909 18.2622 11.6379 17.2932 11.6379C16.7422 11.6379 16.3322 11.7379 16.0632 11.9379C15.7932 12.1369 15.6592 12.3929 15.6592 12.7039C15.6592 13.0099 15.7882 13.2509 16.0472 13.4279C16.2022 13.5319 16.6612 13.6979 17.4252 13.9259C18.4762 14.2289 19.1562 14.4699 19.4652 14.6499C19.7732 14.8299 20.0132 15.0759 20.1852 15.3859C20.3562 15.6959 20.4422 16.0649 20.4422 16.4919C20.4422 17.2849 20.1652 17.9269 19.6122 18.4179C19.0582 18.9079 18.3022 19.1529 17.3452 19.1529C15.5492 19.1539 14.5082 18.2639 14.2212 16.4819Z" fill="#AAC2D4"/>
                <path d="M48.96 18.4669L53.38 7.01392H55.002L59.695 18.4669H57.96L56.627 14.9989H51.805L50.559 18.4669H48.96ZM52.248 13.7639H56.152L54.961 10.5919C54.592 9.61792 54.322 8.82592 54.151 8.21692C54.003 8.95092 53.794 9.68092 53.523 10.4049L52.248 13.7639Z" fill="#AAC2D4"/>
                <path d="M60.6958 8.63092V7.01392H62.0968V8.63092H60.6958ZM60.6958 18.4669V10.1699H62.0968V18.4659H60.6958V18.4669Z" fill="#AAC2D4"/>
                <path d="M94.6401 12.639L95.3171 7.09204H102.257V7.95904H96.2991L95.8131 11.803C96.5571 11.386 97.4271 11.178 98.4251 11.178C99.6771 11.178 100.675 11.511 101.419 12.178C102.163 12.845 102.534 13.733 102.534 14.842C102.534 16.04 102.172 16.97 101.447 17.631C100.722 18.292 99.7021 18.623 98.3871 18.623C97.1791 18.623 96.2001 18.347 95.4501 17.795C94.7001 17.243 94.2711 16.47 94.1631 15.475H95.2601C95.3681 16.251 95.6891 16.838 96.2231 17.237C96.7571 17.635 97.4781 17.835 98.3871 17.835C99.3781 17.835 100.127 17.577 100.632 17.062C101.137 16.547 101.39 15.812 101.39 14.859C101.39 14.026 101.107 13.346 100.541 12.82C99.9761 12.294 99.2031 12.031 98.2251 12.031C97.6841 12.031 97.2141 12.087 96.8141 12.199C96.4141 12.311 95.9911 12.529 95.5461 12.851L94.6401 12.639Z" fill="#AAC2D4"/>
                <path d="M135.201 15.436L136.602 15.248C136.895 16.753 137.637 17.506 138.828 17.506C139.472 17.506 140.029 17.283 140.5 16.837C140.972 16.391 141.207 15.813 141.207 15.104C141.207 14.436 140.991 13.899 140.559 13.492C140.127 13.085 139.596 12.882 138.967 12.882C138.7 12.882 138.369 12.934 137.976 13.038L138.138 11.804L138.358 11.82C139.053 11.82 139.618 11.646 140.051 11.298C140.486 10.95 140.702 10.485 140.702 9.90199C140.702 9.36699 140.52 8.93699 140.157 8.61199C139.794 8.28699 139.351 8.12499 138.829 8.12499C138.287 8.12499 137.83 8.29599 137.457 8.63699C137.085 8.97799 136.848 9.49499 136.745 10.188L135.344 9.93799C135.522 8.95899 135.922 8.21899 136.545 7.71899C137.168 7.21899 137.921 6.96899 138.805 6.96899C139.733 6.96899 140.521 7.24999 141.169 7.81199C141.817 8.37399 142.142 9.082 142.142 9.936C142.142 10.457 142.004 10.917 141.73 11.318C141.455 11.719 141.071 12.034 140.578 12.263C141.034 12.367 141.41 12.539 141.708 12.778C142.006 13.017 142.246 13.338 142.43 13.738C142.614 14.138 142.705 14.586 142.705 15.08C142.705 16.132 142.327 16.992 141.57 17.661C140.814 18.33 139.908 18.664 138.853 18.664C137.874 18.664 137.044 18.372 136.365 17.789C135.683 17.204 135.295 16.42 135.201 15.436Z" fill="#AAC2D4"/>
                <path d="M173.028 16.1231L174.112 15.9591C174.142 16.7251 174.273 17.2491 174.506 17.5311C174.739 17.8131 175.059 17.9551 175.468 17.9551C175.756 17.9551 176.01 17.8831 176.23 17.7401C176.45 17.5951 176.597 17.4151 176.673 17.1961C176.779 16.9001 176.832 16.4511 176.832 15.8491V8.94507H178.036V15.7741C178.036 17.0041 177.813 17.8731 177.366 18.3781C176.919 18.8831 176.289 19.1371 175.475 19.1371C174.682 19.1371 174.091 18.9091 173.703 18.4531C173.252 17.9351 173.028 17.1571 173.028 16.1231Z" fill="#AAC2D4"/>
                <path d="M179.627 15.74L180.763 15.631C180.824 16.183 180.961 16.611 181.176 16.92C181.391 17.227 181.697 17.475 182.097 17.661C182.495 17.848 182.946 17.941 183.446 17.941C184.163 17.941 184.72 17.783 185.117 17.467C185.513 17.151 185.712 16.74 185.712 16.236C185.712 15.94 185.64 15.682 185.496 15.462C185.351 15.241 185.135 15.061 184.844 14.92C184.554 14.779 183.926 14.58 182.961 14.322C182.103 14.092 181.491 13.861 181.128 13.628C180.764 13.396 180.482 13.101 180.279 12.743C180.076 12.385 179.976 11.981 179.976 11.53C179.976 10.719 180.266 10.056 180.847 9.54104C181.427 9.02604 182.21 8.76904 183.196 8.76904C183.873 8.76904 184.465 8.89004 184.972 9.13104C185.479 9.37204 185.873 9.71904 186.155 10.17C186.435 10.621 186.585 11.152 186.605 11.763L185.446 11.859C185.385 11.207 185.17 10.724 184.799 10.41C184.428 10.096 183.905 9.93804 183.235 9.93804C182.552 9.93804 182.033 10.076 181.673 10.352C181.315 10.628 181.136 10.984 181.136 11.422C181.136 11.827 181.267 12.142 181.53 12.365C181.793 12.589 182.384 12.815 183.302 13.043C184.2 13.268 184.831 13.462 185.196 13.627C185.751 13.883 186.171 14.22 186.454 14.636C186.737 15.052 186.879 15.551 186.879 16.128C186.879 16.702 186.731 17.225 186.433 17.695C186.134 18.167 185.729 18.525 185.216 18.77C184.703 19.016 184.086 19.139 183.364 19.139C182.237 19.139 181.34 18.833 180.67 18.223C180 17.611 179.652 16.784 179.627 15.74Z" fill="#AAC2D4"/>
                <path d="M209 22.0908C209 23.6517 210.27 24.9168 211.837 24.9168H228.163C229.73 24.9168 231 23.6515 231 22.0908V5.82606C231 4.26509 229.73 3 228.163 3H211.837C210.27 3 209 4.26527 209 5.82598V22.0907V22.0908Z" fill="#AAC2D4"/>
                <path d="M218.123 12.6946V9.59093H220.865C221.126 9.59093 221.378 9.61259 221.621 9.656C221.863 9.69932 222.078 9.77671 222.264 9.88826C222.451 9.99982 222.6 10.1547 222.712 10.3529C222.824 10.5512 222.88 10.8052 222.88 11.1148C222.88 11.6724 222.712 12.0751 222.376 12.3229C222.041 12.5708 221.611 12.6946 221.089 12.6946H218.123ZM215.194 7.32349V20.5934H221.649C222.246 20.5934 222.827 20.5191 223.393 20.3704C223.959 20.2217 224.463 19.9925 224.904 19.6827C225.346 19.373 225.697 18.9734 225.958 18.484C226.22 17.9946 226.35 17.4153 226.35 16.7462C226.35 15.9161 226.148 15.2068 225.744 14.6183C225.34 14.0297 224.727 13.6177 223.906 13.3823C224.503 13.0974 224.954 12.7318 225.259 12.2858C225.563 11.8397 225.716 11.2822 225.716 10.6131C225.716 9.99357 225.613 9.47321 225.408 9.05192C225.203 8.6307 224.914 8.29305 224.54 8.03903C224.167 7.78502 223.72 7.60224 223.197 7.49077C222.675 7.37922 222.097 7.32349 221.462 7.32349H215.194ZM218.123 18.326V14.6832H221.313C221.947 14.6832 222.457 14.8288 222.843 15.12C223.228 15.4112 223.421 15.8975 223.421 16.579C223.421 16.9259 223.362 17.2109 223.244 17.4339C223.126 17.6569 222.967 17.8335 222.768 17.9636C222.569 18.0937 222.339 18.1866 222.078 18.2424C221.817 18.2981 221.543 18.326 221.257 18.326H218.123Z" fill="white"/>
                <path d="M249 13.4565C249 17.9909 251.645 21.9104 255.482 23.7671L249.996 8.79416C249.358 10.2189 249 11.7956 249 13.4565ZM268.263 12.8786C268.263 11.4622 267.753 10.4821 267.315 9.71911C266.732 8.77491 266.186 7.97637 266.186 7.03209C266.186 5.97897 266.987 4.9988 268.117 4.9988C268.168 4.9988 268.217 5.00499 268.266 5.00812C266.22 3.14049 263.494 2 260.5 2C256.482 2 252.948 4.05384 250.891 7.16346C251.162 7.17232 251.416 7.17782 251.632 7.17782C252.834 7.17782 254.697 7.03217 254.697 7.03217C255.317 6.99581 255.389 7.90335 254.77 7.97637C254.77 7.97637 254.147 8.049 253.454 8.08498L257.642 20.4949L260.159 12.9756L258.367 8.0849C257.747 8.04893 257.161 7.97629 257.161 7.97629C256.541 7.93963 256.614 6.99573 257.234 7.03209C257.234 7.03209 259.133 7.17775 260.263 7.17775C261.465 7.17775 263.328 7.03209 263.328 7.03209C263.948 6.99573 264.021 7.90327 263.401 7.97629C263.401 7.97629 262.777 8.04893 262.085 8.0849L266.241 20.4001L267.388 16.5824C267.971 15.0935 268.263 13.8591 268.263 12.8786ZM260.702 14.4587L257.251 24.4471C258.282 24.7489 259.371 24.9138 260.5 24.9138C261.839 24.9138 263.124 24.6835 264.319 24.2643C264.289 24.2152 264.26 24.1632 264.237 24.1064L260.702 14.4587ZM270.592 7.96048C270.641 8.32511 270.669 8.71648 270.669 9.1384C270.669 10.3005 270.45 11.6074 269.794 13.2417L266.282 23.3588C269.701 21.373 272 17.683 272 13.4566C272 11.4645 271.489 9.59194 270.592 7.96048Z" fill="#AAC2D4"/>
                <path d="M34.77 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M77.104 1.31006V26.0001" stroke="#AAC2D4"/>
                <path d="M118.921 0.874023V25.564" stroke="#AAC2D4"/>
                <path d="M159.771 1.30908V26.0001" stroke="#AAC2D4"/>
                <path d="M199.104 1.65186V26.3439" stroke="#AAC2D4"/>
                <path d="M239 1.65186V26.3439" stroke="#AAC2D4"/>
                </svg>
                                  
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</section>
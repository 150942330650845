import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-yaseer-mortgage-services',
  templateUrl: './yaseer-mortgage-services.component.html',
  styleUrls: ['./yaseer-mortgage-services.component.scss']
})
export class YaseerMortgageServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/birds/.test(loc)) {
        $('body').addClass('tech');
      }
       // scroling top
       $('a.detial-icon').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
    });
  }

}

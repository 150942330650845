import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-webtemplates',
  templateUrl: './home-webtemplates.component.html',
  styleUrls: ['./home-webtemplates.component.scss']
})
export class HomeWebtemplatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, AfterContentChecked  } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked{
  title = 'annasahebsitr';


  ngAfterContentChecked(){
        var bannerHight = $(window).height();
        $('.banner-home').css({ 'min-height': bannerHight});
        // $('.skill').css({ 'min-height': bannerHight });
        // $('.icon').css({ 'min-height': bannerHight });
        $('.painting').css({ 'min-height': bannerHight })
  }

  ngOnInit(): void {
    $(document).ready(() => {
      $(window).resize(function () {
        var bannerHight = $(window).height();
        $('.banner-home').css({ 'min-height': bannerHight});
        // $('.skill').css({ 'min-height': bannerHight });
        // $('.icon').css({ 'min-height': bannerHight });
        $('.painting').css({ 'min-height': bannerHight })
      });


      $(window).trigger('resize');
      
      $('.burgermenu-icon, .navigations li a, .list-updated-li .linkList').click(function () {

        $('.burgermenu-icon').stop().toggleClass('show-popup-menu');

        $('body').stop().toggleClass('overhide');

        $('.popup-nav-style').stop().toggleClass('popup-manu-hide');

        setTimeout(function () {
          $('.popup-nav-style').stop().toggleClass('popup-show3-add');
        }, 200);
        setTimeout(function () {
          $('.popup-nav-style').stop().toggleClass('popup-show2-add');
        }, 400);
        setTimeout(function () {
          $('.popup-nav-style').stop().toggleClass('popup-show1-add');
        }, 800);

      });

      // scroling top
      $('a.pnotographyfooterlink, a.detial-icon, a.viewMore').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
    });
  }
}

<section class="header">
  <div class="banner-content">
    <div class="header-title text-center">
      <h2>God Lamp</h2>
      <p>
        Here are the Most Inspiring Photography My Collections
      </p>
      <a class="button-st">See More</a>
    </div>
  </div>
</section>
<section class="product-box">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink" routerLink="/ganpati-festival">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FGod%20Lamp%2Fgod-lamp_optimized.jpg?alt=media&token=2504dcd2-8383-4cc2-a2b4-7899416aa29b" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">God Lamp</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink" routerLink="/ganpati-festival">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FGod%20Lamp%2Fganpati-festival19_optimized.jpg?alt=media&token=651599c6-5e2e-4310-a154-6729f2a48d6f" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">God Lamp</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink" routerLink="/ganpati-festival">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FGod%20Lamp%2Fphotography_god_lamp_optimized.jpg?alt=media&token=d410a4a2-5099-41e9-8bf1-732426ef21cc" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">God Lamp</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</section>
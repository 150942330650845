import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-applications-templates',
  templateUrl: './applications-templates.component.html',
  styleUrls: ['./applications-templates.component.scss']
})
export class ApplicationsTemplatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/icon/.test(loc)) {
        $('body').addClass('tech');
        $('body').removeClass('home');
        $('body').removeClass('about-bg');
      }
            // scroling top
      $('a.linkHead').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
    })
  }

}

import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-photogarph',
  templateUrl: './photogarph.component.html',
  styleUrls: ['./photogarph.component.scss']
})
export class PhotogarphComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      var loc = window.location.href; // returns the full URL
      $('body').addClass('balcklogo');
      console.log(loc);
      if (/photography/.test(loc)) {
        $('body').addClass('tech');
        $('body').removeClass('home');
        $('body').removeClass('about-bg');
      }
       // scroling top
       $('a.linkHead').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
    });
  }

}

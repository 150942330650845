<section class="skill scrollingWind ">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="skill-wrapper ">
                    <div class="skill-item">
                        <div class="skill-icon">
                            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40.5" cy="40" r="40" fill="url(#paint0_linear_153_2693)" />
                                <rect x="15.4451" y="22.8572" width="48.5714" height="29.2308" rx="3"
                                    fill="url(#paint1_linear_153_2693)" />
                                <path
                                    d="M15.4451 43.9561H64.0165V49.0879C64.0165 50.7448 62.6734 52.0879 61.0165 52.0879H18.4451C16.7882 52.0879 15.4451 50.7448 15.4451 49.0879V43.9561Z"
                                    fill="white" />
                                <rect x="22.4775" y="57.1428" width="34.2857" height="4.3956" rx="2.1978" fill="white" />
                                <path d="M28.8516 57.1428L32.0509 52.0879H47.6303L50.8296 57.1428H28.8516Z" fill="#DCE2F8" />
                                <circle cx="38.9617" cy="47.9121" r="1.97802" fill="#E2E9FF" />
                                <rect x="32.1484" y="26.8132" width="14.7253" height="13.6264" fill="#2A946F" />
                                <circle cx="34.2362" cy="37.0331" r="5.38462" fill="#F96FB1" />
                                <path d="M45.3352 27.9121L52.9486 41.0989H37.7217L45.3352 27.9121Z" fill="#FFD060" />
                                <defs>
                                    <linearGradient id="paint0_linear_153_2693" x1="-7.52198" y1="76.4835" x2="84.7857" y2="10.5494"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#9CBEF7" />
                                        <stop offset="1" stop-color="#A8DFF2" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_153_2693" x1="15.4451" y1="49.6704" x2="62.5825" y2="25.2537"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#D0E4FC" />
                                        <stop offset="1" stop-color="#CAE2EF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div class="skill-content">
                            <h3 class="skill-title">UX Design </h3>
                            <span class="skill-content-text">Adobe Photoshop,
                                Illustrator, Figma</span>
                        </div>
                    </div>
                    <div class="skill-item">
                        <div class="skill-icon">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z"
                                    fill="url(#paint0_linear_153_2707)" />
                                <path
                                    d="M18.022 21.978C18.022 21.0069 18.8092 20.2197 19.7802 20.2197H60.2198C61.1908 20.2197 61.978 21.0069 61.978 21.978V58.0219C61.978 58.993 61.1908 59.7802 60.2198 59.7802H19.7802C18.8092 59.7802 18.022 58.993 18.022 58.0219V21.978Z"
                                    fill="#E4E9FA" />
                                <path
                                    d="M18.022 21.978C18.022 21.0069 18.8092 20.2197 19.7802 20.2197H60.2198C61.1908 20.2197 61.978 21.0069 61.978 21.978V26.8131H18.022V21.978Z"
                                    fill="white" />
                                <path d="M22.4175 31.2087H35.6043V55.3846H22.4175V31.2087Z" fill="#50C3E2" />
                                <path d="M37.8022 31.2087H57.5825V42.1978H37.8022V31.2087Z" fill="#F96FB1" />
                                <path d="M37.8022 44.3955H57.5825V55.3845H37.8022V44.3955Z" fill="#71F1A6" />
                                <path
                                    d="M25.055 23.5165C25.055 24.3662 24.3662 25.055 23.5165 25.055C22.6668 25.055 21.978 24.3662 21.978 23.5165C21.978 22.6668 22.6668 21.978 23.5165 21.978C24.3662 21.978 25.055 22.6668 25.055 23.5165Z"
                                    fill="#F96FB1" />
                                <path
                                    d="M29.4505 23.5165C29.4505 24.3662 28.7617 25.055 27.912 25.055C27.0623 25.055 26.3735 24.3662 26.3735 23.5165C26.3735 22.6668 27.0623 21.978 27.912 21.978C28.7617 21.978 29.4505 22.6668 29.4505 23.5165Z"
                                    fill="#FFD165" />
                                <path
                                    d="M33.8462 23.5165C33.8462 24.3662 33.1574 25.055 32.3077 25.055C31.4581 25.055 30.7693 24.3662 30.7693 23.5165C30.7693 22.6668 31.4581 21.978 32.3077 21.978C33.1574 21.978 33.8462 22.6668 33.8462 23.5165Z"
                                    fill="#49BC90" />
                                <defs>
                                    <linearGradient id="paint0_linear_153_2707" x1="6.37363" y1="62.7473" x2="71.6484" y2="14.8352"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#2279D2" />
                                        <stop offset="1" stop-color="#49C3AF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div class="skill-content">
                            <h3 class="skill-title">Development</h3>
                            <span class="skill-content-text">HTML, CSS, JavaScript,
                                Jquery Angular, React</span>
                        </div>
                    </div>
                    <div class="skill-item">
                        <div class="skill-icon">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z"
                                    fill="url(#paint0_linear_153_2748)" />
                                <path
                                    d="M18.022 21.978C18.022 21.0069 18.8092 20.2197 19.7802 20.2197H60.2198C61.1908 20.2197 61.978 21.0069 61.978 21.978V58.0219C61.978 58.993 61.1908 59.7802 60.2198 59.7802H19.7802C18.8092 59.7802 18.022 58.993 18.022 58.0219V21.978Z"
                                    fill="#E4E9FA" />
                                <path
                                    d="M18.022 21.978C18.022 21.0069 18.8092 20.2197 19.7802 20.2197H60.2198C61.1908 20.2197 61.978 21.0069 61.978 21.978V26.8131H18.022V21.978Z"
                                    fill="white" />
                                <path d="M22.4176 31.2087H56.2638V55.3846H22.4176V31.2087Z" fill="#50C3E2" />
                                <path
                                    d="M25.055 23.5165C25.055 24.3662 24.3662 25.055 23.5165 25.055C22.6668 25.055 21.978 24.3662 21.978 23.5165C21.978 22.6668 22.6668 21.978 23.5165 21.978C24.3662 21.978 25.055 22.6668 25.055 23.5165Z"
                                    fill="#F96FB1" />
                                <path
                                    d="M29.4506 23.5165C29.4506 24.3662 28.7618 25.055 27.9121 25.055C27.0624 25.055 26.3737 24.3662 26.3737 23.5165C26.3737 22.6668 27.0624 21.978 27.9121 21.978C28.7618 21.978 29.4506 22.6668 29.4506 23.5165Z"
                                    fill="#FFD165" />
                                <path
                                    d="M33.8462 23.5165C33.8462 24.3662 33.1574 25.055 32.3077 25.055C31.4581 25.055 30.7693 24.3662 30.7693 23.5165C30.7693 22.6668 31.4581 21.978 32.3077 21.978C33.1574 21.978 33.8462 22.6668 33.8462 23.5165Z"
                                    fill="#49BC90" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.3737 38.6813C26.3737 38.4385 26.5705 38.2417 26.8132 38.2417H37.6923C37.9351 38.2417 38.1319 38.4385 38.1319 38.6813C38.1319 38.924 37.9351 39.1208 37.6923 39.1208H26.8132C26.5705 39.1208 26.3737 38.924 26.3737 38.6813Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.3737 41.7582C26.3737 41.5154 26.5705 41.3186 26.8132 41.3186H43.077C43.3197 41.3186 43.5165 41.5154 43.5165 41.7582C43.5165 42.0009 43.3197 42.1977 43.077 42.1977H26.8132C26.5705 42.1977 26.3737 42.0009 26.3737 41.7582Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.3737 44.8351C26.3737 44.5923 26.5705 44.3955 26.8132 44.3955H32.9671C33.2098 44.3955 33.4066 44.5923 33.4066 44.8351C33.4066 45.0778 33.2098 45.2746 32.9671 45.2746H26.8132C26.5705 45.2746 26.3737 45.0778 26.3737 44.8351Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.3737 47.912C26.3737 47.6692 26.5705 47.4724 26.8132 47.4724H40.3297C40.5725 47.4724 40.7693 47.6692 40.7693 47.912C40.7693 48.1547 40.5725 48.3515 40.3297 48.3515H26.8132C26.5705 48.3515 26.3737 48.1547 26.3737 47.912Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M29.5178 34.5363C29.6221 34.7555 29.5291 35.0178 29.3099 35.1222L27.8799 35.8031L29.2941 36.4092C29.5172 36.5048 29.6206 36.7632 29.5249 36.9864C29.4293 37.2095 29.1709 37.3129 28.9478 37.2172L26.6401 36.2282C26.4813 36.1602 26.3772 36.0055 26.3737 35.8329C26.3703 35.6602 26.4683 35.5016 26.6242 35.4273L28.9319 34.3284C29.1511 34.2241 29.4134 34.3171 29.5178 34.5363Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.644 49.9051C26.5309 50.1199 26.6134 50.3857 26.8283 50.4988L28.0279 51.1302L26.8448 51.6906C26.6254 51.7945 26.5318 52.0566 26.6357 52.276C26.7397 52.4954 27.0018 52.589 27.2211 52.4851L29.1992 51.5481C29.3497 51.4768 29.447 51.3266 29.4505 51.1601C29.454 50.9936 29.3631 50.8395 29.2157 50.7619L27.2377 49.7208C27.0229 49.6078 26.7571 49.6903 26.644 49.9051Z"
                                    fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_153_2748" x1="6.37363" y1="62.7473" x2="71.6484" y2="14.8352"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CBEA6F" />
                                        <stop offset="1" stop-color="#FFD69F" />
                                        <stop offset="1" stop-color="#24B58C" />
                                    </linearGradient>
                                </defs>
                            </svg>
        
                        </div>
                        <div class="skill-content">
                            <h3 class="skill-title">Integration</h3>
                            <span class="skill-content-text">Salesforce , Mendix ,
                                Magento, Siebel, Laravel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="header">
  <div class="banner-content">
    <div class="header-title text-center">
      <h2>My photography collection</h2>
      <p>
        Here are the Most Inspiring Photography My Collections
      </p>
    </div>
  </div>
</section>
<section class="product-box">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink linkHead" routerLink="/ganpati-festival">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FGanpati%2Fganpati-festival-thems_optimized.jpg?alt=media&token=9a59fbaf-396a-438d-a233-7f08e077986a" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Pune Ganpati festival 2019 </h3>
            <p class="photographyContent">This photograph was taken at Pune Ganpati festival 2019, That time I had used cannon 200D and Tamron lens 600mm. I like attend festivals and take nice photos</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>

        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink" routerLink="/insets">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FInsets%2Finsets-catalogs-min.jpg?alt=media&token=f5c56a13-6b25-4228-933a-5e2ef0adaa08" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">insets</h3>
            <p class="photographyContent">
            </p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink" routerLink="/animal" >
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2Fdeer_optimized.jpg?alt=media&token=2fa9fca3-912f-45b9-bf65-a8c7ed0a7e90" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Deer</h3>
            <!-- <p class="photographyContent">
              Deer
            </p> -->
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink linkHead" routerLink="/birds">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FBirds%2Fcategory_bird-thume_optimized.jpg?alt=media&token=73184aa8-8e7b-4d23-ba5b-2fcb84218e0b" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Birds </h3>
            <!-- <p class="photographyContent"></p> -->
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink linkHead" routerLink="/flowers">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FFlowers%2Fcategory_flowers-thume_optimized.jpg?alt=media&token=6b0cfa4c-d1d3-4846-bebd-fc799c394fb2" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Flowers</h3>
            <p class="photographyContent">Every flower is a soul blossoming in nature.</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>


        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink linkHead" routerLink="/godlamp">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2FGod%20Lamp%2Fganpati-festival19_optimized.jpg?alt=media&token=651599c6-5e2e-4310-a154-6729f2a48d6f" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">God Lamp</h3>
            <p class="photographyContent">Light symbolizes knowledge, darkness and ignorance. Lord is the “Knowledge principle” (Chaitanya), who is the source, enlivener and the illuminator of all knowledge.</p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>
</section>
<section class="header">
  <div class="banner-content">
    <div class="header-title text-center">
      <h2>Animal</h2>
      <p>
        Here are the Most Inspiring Photography My Collections
      </p>
    </div>
  </div>
</section>
<section class="product-box">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2Ftigar2_optimized.jpg?alt=media&token=c47cb9c4-3635-494c-b0d0-d0363e5179f2" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2Ftigar_optimized.jpg?alt=media&token=642157c4-0bee-4792-abfd-ebfe698f4790" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div>

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2FPhotography4-min.jpg?alt=media&token=bf669525-216d-4b3b-9510-953eaf02dbfe" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 
        
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2FPhotography5-min.jpg?alt=media&token=89a2e4e1-a2f8-4ed4-af66-5432060cc57a" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2Fdeer_optimized.jpg?alt=media&token=2fa9fca3-912f-45b9-bf65-a8c7ed0a7e90" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal</h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2Fdog-min.jpg?alt=media&token=b9ddf893-acdf-47d9-8f98-b87535e2c228" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal </h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

        
        <div class="photographyBox">
          <div class="photographyBoxImg">
            <a class="photographyBoxLink">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/photography%2Fcategory_animals%2FPhotography9-min.jpg?alt=media&token=92f5cda4-51d8-4c7a-b125-ff2c96c61ddd" />
            </a>
          </div>
          <div class="photographyBoxContent">
            <h3 class="photographyContentTitle">Animal </h3>
            <p class="photographyContent"></p>
            <!-- <a class="moreoptions">More</a> -->
            <div class="socialiconBox">
              <a class="socialicon"></a>
            </div>
          </div>
        </div> 

      </div>
    </div>
  </div>
</section>
import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $(window).scroll(function() {
        if ($(this).scrollTop()>450)
         {
            $('.header-set').addClass('fixheader');
         }
        else
         {
          $('.header-set').removeClass('fixheader');
         };
      });

    
    });
  }

}

import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      var bannerContent = $('.banner-content').height() / 2;
      // $('.banner-content').css({ 'margin-top': -bannerContent });
      var windowsMinhight = $(window).height();
      // $('.skillst').css({ 'min-height': windowsMinhight });

      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/home/.test(loc)) {
        $('body').removeClass('tech');
        $('body').addClass('home');
        $('body').removeClass('about-bg');
      }
      $(".side-bannertab-item").click(function(){
        console.log($(this).hasClass("home"))
        if($(this).hasClass("home")){
          $('.home').addClass('active');
          $('.Skill').removeClass('active');
          $('.Icon').removeClass('active');
          $('.mywork').removeClass('active');
        }
        if ($(this).hasClass("Skill")){
          $('.Skill').addClass('active');
          $('.Icon').removeClass('active');
          $('.mywork').removeClass('active');
          $('.home').removeClass('active');
        }
        if ($(this).hasClass("Icon")){
          $('.Icon').addClass('active');
          $('.Skill').removeClass('active');
          $('.mywork').removeClass('active');
          $('.home').removeClass('active');
        }
        if ($(this).hasClass("mywork")){
          $('.mywork').addClass('active');
          $('.Skill').removeClass('active');
          $('.Icon').removeClass('active');
          $('.home').removeClass('active');
        }
      });

      $(window).scroll(function() {
        if ($(this).scrollTop()>250)
         {
            $('.side-bannertab-item').addClass('colorchange');
         }
        else
         {
          $('.side-bannertab-item').removeClass('colorchange');
         };

         if($(this).scrollTop()>0){
          $('.home').addClass('active');
          $('.Skill').removeClass('active');
          $('.Icon').removeClass('active');
          $('.mywork').removeClass('active');
        }
        if ($(this).scrollTop()> windowsMinhight - 200){
          $('.Skill').addClass('active');
          $('.Icon').removeClass('active');
          $('.mywork').removeClass('active');
          $('.home').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 2 - 300){
          $('.Icon').addClass('active');
          $('.Skill').removeClass('active');
          $('.mywork').removeClass('active');
          $('.home').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 2){
          $('.mywork').addClass('active');
          $('.Skill').removeClass('active');
          $('.Icon').removeClass('active');
          $('.home').removeClass('active');
        }
      });

     

      $('body').removeClass('balcklogo');
       // scroling top
       $('a.view-more, a.viewMore, a.viewMoreArrowIcon, a.viewdetail, a.view-more-st').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
    });
  }

}

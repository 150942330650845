import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/blogs/.test(loc)) {
        $('body').addClass('tech');
        $('body').removeClass('home');
        $('body').removeClass('about-bg');
      }
    })
  }

}

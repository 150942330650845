import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mi-internal-jobportal',
  templateUrl: './mi-internal-jobportal.component.html',
  styleUrls: ['./mi-internal-jobportal.component.scss']
})
export class MiInternalJobportalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

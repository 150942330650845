<header class="header-set">
  <!--********************
*Logo
*Header
*Burgarmenu
***********************-->
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="header-box">
          <div class="logo">
            <a class="logo-annasahebkute" routerLink="/home">
              <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/other_images%2Fannasahebkute_logo.svg?alt=media&token=07aaad97-1f6e-4a00-a1fc-e091de7b09dd"> 
            </a>
          </div>
          <div class="burgermenu-icon">
            <span class="strip strip1"></span>
            <span class="strip strip2"></span>
            <span class="strip strip3"></span>
          </div>
          <ul class="menu-list">
            <li class="menu-list-item active-menu">
              <a class="menu-list-link" routerLink="/home" routerLinkActive="active">Home</a>
            </li>
            <li class="menu-list-item"> 
              <a class="menu-list-link" routerLink="/mywork" routerLinkActive="active">My Work</a> 
            </li> 
            <li class="menu-list-item">                         
              <a class="menu-list-link" routerLink="/aboutme" routerLinkActive="active">About Me</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--********************
*Popup Menu
***********************-->
  <div class="popup-nav-style">
    <div class="menupopup-sbt">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 popup-show1">
            <div class="designuiux-iocn">
              <svg width="201" height="132" viewBox="0 0 201 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.816 0.896484V27.6079C33.816 31.7798 32.6354 35.0397 30.2742 37.3876C27.913 39.7174 24.7148 40.8823 20.6796 40.8823C16.6445 40.8823 13.4463 39.7174 11.0851 37.3876C8.72387 35.0397 7.53447 31.816 7.51685 27.7162V0.896484H8.94414V27.5537C8.94414 31.2019 10.0102 34.0916 12.1423 36.2227C14.2921 38.3538 17.1378 39.4194 20.6796 39.4194C24.2214 39.4194 27.0584 38.3538 29.1905 36.2227C31.3226 34.0916 32.3887 31.1929 32.3887 27.5266V0.896484H33.816Z" fill="white"/>
                <path d="M46.503 40.3405H45.0757V0.896484H46.503V40.3405Z" fill="white"/>
                <g filter="url(#filter0_d_1128_370)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.1293 9.89343L86.13 9.89455V16.7268C86.0921 16.7247 86.0539 16.7237 86.0155 16.7237C84.8839 16.7237 83.9665 17.641 83.9665 18.7727C83.9665 19.9044 84.8839 20.8218 86.0155 20.8218C86.0539 20.8218 86.0921 20.8207 86.13 20.8186V25.2614H86.1293C84.7633 25.2614 83.7615 25.4891 81.1205 28.2212C78.4795 30.9533 74.9353 30.8774 73.4934 30.4979L86.1293 9.89343ZM86.1308 9.89343L86.13 9.89476V16.7268C86.1672 16.7247 86.2047 16.7237 86.2424 16.7237C87.3507 16.7237 88.2491 17.6411 88.2491 18.7727C88.2491 19.9044 87.3507 20.8218 86.2424 20.8218C86.2047 20.8218 86.1672 20.8207 86.13 20.8186V25.2614H86.1308C87.4687 25.2614 88.4498 25.4891 91.0363 28.2212C93.6228 30.9533 97.0938 30.8774 98.506 30.498L86.1308 9.89343Z" fill="white"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M85.489 41.0889C96.0928 41.0889 104.689 32.4928 104.689 21.889C104.689 11.2852 96.0928 2.68918 85.489 2.68918C74.8852 2.68918 66.2892 11.2852 66.2892 21.889C66.2892 32.4928 74.8852 41.0889 85.489 41.0889ZM85.489 41.8819C96.5308 41.8819 105.482 32.9308 105.482 21.889C105.482 10.8472 96.5308 1.89612 85.489 1.89612C74.4472 1.89612 65.4961 10.8472 65.4961 21.889C65.4961 32.9308 74.4472 41.8819 85.489 41.8819Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M107.262 0.616142C107.417 0.770997 107.417 1.02207 107.262 1.17692L99.7643 8.67426C99.6095 8.82912 99.3584 8.82912 99.2035 8.67426C99.0487 8.51941 99.0487 8.26834 99.2035 8.11348L106.701 0.616142C106.856 0.461286 107.107 0.461286 107.262 0.616142Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.2741 35.6038C72.429 35.7587 72.429 36.0097 72.2741 36.1646L64.7768 43.6619C64.6219 43.8168 64.3709 43.8168 64.216 43.6619C64.0611 43.5071 64.0611 43.256 64.216 43.1012L71.7133 35.6038C71.8682 35.449 72.1193 35.449 72.2741 35.6038Z" fill="white"/>
                <path d="M153.11 1.89612V28.6075C153.11 32.7795 151.869 36.0394 149.388 38.3872C146.907 40.717 143.546 41.8819 139.306 41.8819C135.066 41.8819 131.705 40.717 129.224 38.3872C126.743 36.0394 125.493 32.8156 125.475 28.7159V1.89612H126.975V28.5533C126.975 32.2015 128.095 35.0912 130.335 37.2223C132.594 39.3535 135.584 40.419 139.306 40.419C143.028 40.419 146.009 39.3535 148.249 37.2223C150.49 35.0912 151.61 32.1925 151.61 28.5262V1.89612H153.11Z" fill="white"/>
                <path d="M175.245 20.0739L188.299 1.89612H190.187L176.189 21.2118L190.715 41.3401H188.854L175.245 22.3496L161.58 41.3401H159.775L174.301 21.2118L160.303 1.89612H162.191L175.245 20.0739Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.77368 104.59H26.5012V124.367H6.77368V104.59ZM7.56675 105.383V123.574H25.7081V105.383H7.56675Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.6931 104.59H60.4206V124.367H40.6931V104.59ZM41.4862 105.383V123.574H59.6276V105.383H41.4862Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.6887 104.59H93.4064L91.5525 123.747L83.9446 125.518L76.5424 123.642L74.6887 104.59ZM75.5627 105.383L77.2778 123.01L83.9525 124.702L90.818 123.104L92.5329 105.383H75.5627Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M107.799 104.637H126.517L124.663 123.795L117.055 125.567L109.653 123.69L107.799 104.637ZM108.673 105.431L110.388 123.059L117.063 124.751L123.928 123.152L125.643 105.431H108.673Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M139.918 104.59H158.636L156.782 123.747L149.174 125.518L141.772 123.642L139.918 104.59ZM140.792 105.383L142.507 123.01L149.182 124.702L156.048 123.104L157.762 105.383H140.792Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M181.063 103.042L191.407 106.904L189.898 120.493L181.261 125.622L172.63 120.358L171.121 106.908L181.063 103.042ZM181.069 103.89L171.977 107.426L173.375 119.883L181.267 124.696L189.153 120.013L190.551 107.431L181.069 103.89Z" fill="white"/>
                <path d="M10.5408 119.037V109.954H13.6306C14.3586 109.954 14.8955 110.012 15.2405 110.128C15.7131 110.285 16.0906 110.576 16.373 111.002C16.6545 111.428 16.7965 111.95 16.7965 112.57C16.7965 113.387 16.5665 114.055 16.1073 114.571C15.6481 115.087 14.8543 115.345 13.7265 115.345H11.6249V119.038H10.5408V119.037ZM11.6249 114.272H13.7463C14.4157 114.272 14.9034 114.135 15.2135 113.86C15.5228 113.586 15.6775 113.172 15.6775 112.618C15.6775 112.259 15.6013 111.951 15.4483 111.695C15.296 111.439 15.112 111.264 14.8955 111.168C14.6798 111.073 14.2896 111.026 13.7257 111.026H11.6241V114.272H11.6249Z" fill="#F7F7F7"/>
                <path d="M17.6553 117.066L18.6585 116.893C18.718 117.357 18.8782 117.702 19.1415 117.929C19.4048 118.156 19.7537 118.269 20.1891 118.269C20.6253 118.269 20.9624 118.17 21.2003 117.974C21.4382 117.779 21.558 117.529 21.558 117.228C21.558 116.968 21.4556 116.766 21.251 116.621C21.1099 116.522 20.7689 116.398 20.2272 116.249C19.4452 116.031 18.9218 115.844 18.6577 115.691C18.3936 115.539 18.189 115.334 18.0439 115.078C17.8987 114.822 17.8266 114.531 17.8266 114.204C17.8266 113.643 18.0248 113.186 18.4206 112.835C18.8163 112.484 19.3635 112.308 20.0646 112.308C20.5056 112.308 20.8934 112.38 21.228 112.524C21.5619 112.67 21.8181 112.863 21.9949 113.107C22.1726 113.351 22.2947 113.7 22.3629 114.153L21.3811 114.303C21.2899 113.584 20.8601 113.225 20.0916 113.225C19.6546 113.225 19.3294 113.304 19.1161 113.463C18.902 113.621 18.7957 113.824 18.7957 114.07C18.7957 114.313 18.898 114.504 19.1034 114.644C19.2263 114.727 19.5904 114.858 20.1963 115.039C21.0298 115.28 21.5691 115.471 21.8141 115.613C22.0584 115.756 22.2487 115.951 22.3851 116.197C22.5207 116.443 22.5889 116.736 22.5889 117.074C22.5889 117.703 22.3693 118.212 21.9307 118.602C21.4913 118.99 20.8918 119.185 20.1328 119.185C18.7085 119.185 17.8829 118.48 17.6553 117.066Z" fill="#F7F7F7"/>
                <path d="M45.2056 118.641L48.7109 109.558H49.9973L53.7191 118.641H52.3432L51.286 115.89H47.4618L46.4737 118.641H45.2056ZM47.8132 114.911H50.9093L49.9648 112.395C49.6721 111.623 49.458 110.995 49.3224 110.512C49.205 111.094 49.0392 111.673 48.8243 112.247L47.8132 114.911Z" fill="#F7F7F7"/>
                <path d="M54.5129 110.84V109.558H55.624V110.84H54.5129ZM54.5129 118.641V112.061H55.624V118.64H54.5129V118.641Z" fill="#F7F7F7"/>
                <path d="M81.4327 114.019L81.9696 109.62H87.4735V110.307H82.7484L82.363 113.356C82.953 113.025 83.643 112.86 84.4345 112.86C85.4274 112.86 86.2189 113.124 86.8089 113.653C87.399 114.182 87.6932 114.886 87.6932 115.766C87.6932 116.716 87.4061 117.453 86.8311 117.978C86.2561 118.502 85.4472 118.764 84.4043 118.764C83.4463 118.764 82.6699 118.545 82.0751 118.108C81.4803 117.67 81.1401 117.057 81.0544 116.268H81.9244C82.0101 116.883 82.2647 117.349 82.6882 117.665C83.1117 117.981 83.6834 118.139 84.4043 118.139C85.1903 118.139 85.7843 117.935 86.1848 117.526C86.5853 117.118 86.7859 116.535 86.7859 115.779C86.7859 115.119 86.5615 114.579 86.1126 114.162C85.6645 113.745 85.0515 113.536 84.2759 113.536C83.8468 113.536 83.4741 113.581 83.1569 113.67C82.8396 113.759 82.5042 113.931 82.1513 114.187L81.4327 114.019Z" fill="#F7F7F7"/>
                <path d="M113.6 116.237L114.711 116.088C114.944 117.281 115.532 117.879 116.477 117.879C116.988 117.879 117.429 117.702 117.803 117.348C118.177 116.994 118.363 116.536 118.363 115.974C118.363 115.444 118.192 115.018 117.85 114.695C117.507 114.372 117.086 114.211 116.587 114.211C116.375 114.211 116.113 114.253 115.801 114.335L115.93 113.356L116.104 113.369C116.655 113.369 117.103 113.231 117.447 112.955C117.792 112.679 117.963 112.31 117.963 111.848C117.963 111.424 117.819 111.083 117.531 110.825C117.243 110.567 116.892 110.439 116.478 110.439C116.048 110.439 115.685 110.574 115.389 110.845C115.094 111.115 114.907 111.525 114.825 112.075L113.714 111.877C113.855 111.1 114.172 110.513 114.666 110.117C115.16 109.72 115.757 109.522 116.459 109.522C117.195 109.522 117.819 109.745 118.333 110.191C118.847 110.636 119.105 111.198 119.105 111.875C119.105 112.288 118.996 112.653 118.778 112.971C118.56 113.289 118.256 113.539 117.865 113.72C118.226 113.803 118.524 113.939 118.761 114.129C118.997 114.318 119.187 114.573 119.333 114.89C119.479 115.207 119.551 115.563 119.551 115.955C119.551 116.789 119.252 117.471 118.651 118.001C118.052 118.532 117.333 118.797 116.497 118.797C115.72 118.797 115.062 118.565 114.523 118.103C113.983 117.639 113.675 117.017 113.6 116.237Z" fill="#F7F7F7"/>
                <path d="M143.6 116.782L144.459 116.652C144.483 117.26 144.587 117.675 144.772 117.899C144.957 118.123 145.21 118.235 145.535 118.235C145.763 118.235 145.965 118.178 146.139 118.065C146.313 117.95 146.43 117.807 146.49 117.633C146.574 117.398 146.616 117.042 146.616 116.565V111.09H147.571V116.505C147.571 117.481 147.394 118.17 147.04 118.571C146.685 118.971 146.186 119.173 145.54 119.173C144.911 119.173 144.443 118.992 144.135 118.63C143.777 118.219 143.6 117.602 143.6 116.782Z" fill="#F7F7F7"/>
                <path d="M148.833 116.478L149.734 116.391C149.782 116.829 149.891 117.169 150.061 117.414C150.232 117.657 150.475 117.854 150.792 118.001C151.108 118.15 151.465 118.223 151.862 118.223C152.43 118.223 152.872 118.098 153.187 117.848C153.501 117.597 153.659 117.271 153.659 116.871C153.659 116.637 153.602 116.432 153.488 116.257C153.373 116.082 153.201 115.939 152.97 115.828C152.74 115.716 152.242 115.558 151.477 115.353C150.797 115.171 150.311 114.988 150.023 114.803C149.735 114.619 149.511 114.385 149.35 114.101C149.189 113.817 149.11 113.497 149.11 113.139C149.11 112.496 149.34 111.97 149.801 111.562C150.261 111.153 150.882 110.949 151.663 110.949C152.2 110.949 152.67 111.045 153.072 111.237C153.474 111.428 153.787 111.703 154.01 112.061C154.232 112.418 154.351 112.839 154.367 113.324L153.448 113.4C153.399 112.883 153.229 112.5 152.935 112.251C152.641 112.002 152.226 111.877 151.694 111.877C151.153 111.877 150.741 111.986 150.456 112.205C150.172 112.424 150.03 112.706 150.03 113.053C150.03 113.375 150.134 113.624 150.342 113.801C150.551 113.979 151.019 114.158 151.748 114.339C152.46 114.517 152.96 114.671 153.25 114.802C153.69 115.005 154.023 115.272 154.247 115.602C154.472 115.932 154.584 116.328 154.584 116.786C154.584 117.241 154.467 117.656 154.231 118.028C153.994 118.403 153.672 118.687 153.265 118.881C152.859 119.076 152.369 119.174 151.797 119.174C150.903 119.174 150.192 118.931 149.66 118.447C149.129 117.962 148.853 117.306 148.833 116.478Z" fill="#F7F7F7"/>
                <path d="M177.349 117.848L180.854 108.765H182.14L185.862 117.848H184.486L183.429 115.096H179.605L178.618 117.848H177.349ZM179.957 114.118H183.053L182.109 111.602C181.817 110.83 181.602 110.202 181.466 109.719C181.349 110.301 181.183 110.88 180.968 111.454L179.957 114.118Z" fill="#F7F7F7"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.5554 124.615V105.034H34.3485V124.615H33.5554Z" fill="#F9F9F9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.1292 124.615V105.034H67.9222V124.615H67.1292Z" fill="#F9F9F9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M100.293 124.269V104.688H101.086V124.269H100.293Z" fill="#F9F9F9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M132.689 124.615V105.033H133.483V124.615H132.689Z" fill="#F9F9F9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M163.883 124.888V105.305H164.676V124.888H163.883Z" fill="#F9F9F9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M190.715 95.2713H5.93042V94.4783H190.715V95.2713Z" fill="white"/>
                <path d="M7.68213 80.9961V52.2413H15.799C18.3006 52.2413 20.5125 52.7943 22.4348 53.9002C24.357 55.0062 25.8382 56.5795 26.8783 58.6203C27.9316 60.661 28.4649 63.0046 28.478 65.651V67.4877C28.478 70.1999 27.9514 72.5764 26.8981 74.6171C25.858 76.6579 24.3636 78.2246 22.415 79.3174C20.4796 80.4102 18.2216 80.9698 15.6411 80.9961H7.68213ZM11.474 55.3617V77.8955H15.4633C18.3862 77.8955 20.6573 76.987 22.2768 75.1701C23.9094 73.3532 24.7257 70.766 24.7257 67.4087V65.73C24.7257 62.4648 23.9555 59.9303 22.415 58.1266C20.8878 56.3096 18.7153 55.388 15.8978 55.3617H11.474Z" fill="white"/>
                <path d="M50.4786 67.7049H38.0169V77.8955H52.493V80.9961H34.225V52.2413H52.2955V55.3617H38.0169V64.6043H50.4786V67.7049Z" fill="white"/>
                <path d="M65.6855 68.1789C62.4335 67.2441 60.0636 66.0986 58.5758 64.7425C57.1012 63.3733 56.3639 61.688 56.3639 59.6867C56.3639 57.4222 57.2658 55.5526 59.0695 54.078C60.8864 52.5902 63.2432 51.8463 66.1397 51.8463C68.1146 51.8463 69.8723 52.2281 71.4128 52.9918C72.9664 53.7554 74.1645 54.8087 75.0071 56.1516C75.8629 57.4946 76.2908 58.9626 76.2908 60.5557H72.4792C72.4792 58.8178 71.9262 57.4551 70.8203 56.4676C69.7143 55.467 68.1541 54.9667 66.1397 54.9667C64.2701 54.9667 62.8087 55.3814 61.7554 56.2109C60.7153 57.0272 60.1952 58.1661 60.1952 59.6275C60.1952 60.7993 60.6889 61.7933 61.6764 62.6096C62.677 63.4128 64.3689 64.1501 66.7519 64.8215C69.1482 65.493 71.0178 66.2369 72.3607 67.0532C73.7168 67.8563 74.7175 68.7977 75.3626 69.8773C76.0209 70.9569 76.3501 72.2275 76.3501 73.6889C76.3501 76.0193 75.4416 77.8889 73.6247 79.2977C71.8077 80.6933 69.3786 81.3911 66.3372 81.3911C64.3623 81.3911 62.519 81.0159 60.8074 80.2654C59.0958 79.5018 57.7727 78.4616 56.8379 77.145C55.9162 75.8284 55.4554 74.3341 55.4554 72.662H59.267C59.267 74.3999 59.9056 75.7757 61.1827 76.7895C62.473 77.7902 64.1911 78.2905 66.3372 78.2905C68.3385 78.2905 69.8723 77.8823 70.9388 77.066C72.0052 76.2497 72.5385 75.1372 72.5385 73.7284C72.5385 72.3196 72.0447 71.2334 71.0573 70.4698C70.0698 69.693 68.2792 68.9294 65.6855 68.1789Z" fill="white"/>
                <path d="M85.2964 80.9961H81.5046V52.2413H85.2964V80.9961Z" fill="white"/>
                <path d="M113.479 77.224C112.504 78.6196 111.142 79.6663 109.39 80.3641C107.653 81.0488 105.625 81.3911 103.308 81.3911C100.964 81.3911 98.8839 80.8447 97.067 79.7519C95.25 78.646 93.8413 77.0792 92.8406 75.0516C91.8532 73.024 91.3463 70.6739 91.3199 68.0011V65.493C91.3199 61.1613 92.3271 57.804 94.3416 55.4209C96.3691 53.0378 99.213 51.8463 102.873 51.8463C105.875 51.8463 108.291 52.6165 110.121 54.157C111.951 55.6842 113.07 57.8566 113.479 60.6742H109.687C108.976 56.8692 106.711 54.9667 102.893 54.9667C100.352 54.9667 98.4231 55.862 97.1065 57.6526C95.803 59.43 95.1447 62.0106 95.1315 65.3943V67.7444C95.1315 70.9701 95.8688 73.5375 97.3434 75.4466C98.818 77.3425 100.813 78.2905 103.327 78.2905C104.749 78.2905 105.994 78.1325 107.06 77.8165C108.126 77.5005 109.009 76.9673 109.706 76.2168V69.7588H103.051V66.678H113.479V77.224Z" fill="white"/>
                <path d="M141.917 80.9961H138.106L123.63 58.8375V80.9961H119.818V52.2413H123.63L138.145 74.4986V52.2413H141.917V80.9961Z" fill="white"/>
                <path d="M164.905 67.7049H152.444V77.8955H166.92V80.9961H148.652V52.2413H166.722V55.3617H152.444V64.6043H164.905V67.7049Z" fill="white"/>
                <path d="M182.186 69.3638H175.432V80.9961H171.62V52.2413H181.139C184.378 52.2413 186.867 52.9786 188.604 54.4532C190.356 55.9278 191.231 58.0739 191.231 60.8914C191.231 62.682 190.744 64.2422 189.77 65.572C188.809 66.9018 187.466 67.8958 185.741 68.5541L192.495 80.7591V80.9961H188.427L182.186 69.3638ZM175.432 66.2632H181.258C183.14 66.2632 184.635 65.7761 185.741 64.8018C186.86 63.8275 187.419 62.524 187.419 60.8914C187.419 59.114 186.886 57.7513 185.82 56.8034C184.767 55.8554 183.239 55.3748 181.238 55.3617H175.432V66.2632Z" fill="white"/>
                <defs>
                <filter id="filter0_d_1128_370" x="69.4934" y="9.89343" width="33.0125" height="28.797" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1128_370"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1128_370" result="shape"/>
                </filter>
                </defs>
              </svg>
            </div>
            <div class="qulity-icon">
              <svg width="176" height="55" viewBox="0 0 176 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5608 49.2636C15.992 49.2636 16.3885 44.9017 17.9747 42.9191C18.7678 45.6948 20.3539 44.1087 20.7504 46.4879C21.0112 48.0524 20.2217 48.9992 19.5608 49.2636Z" fill="white"/>
                <path d="M15.7067 46.1163C12.1387 46.1921 12.027 41.333 14.8027 39.7468C15.6546 42.5051 16.39 40.9457 16.837 43.3159C17.131 44.8746 16.3618 45.8379 15.7067 46.1163Z" fill="white"/>
                <path d="M11.6302 42.126C8.11004 41.5392 9.40167 36.8257 12.4233 35.7815C12.7492 38.6499 13.2165 37.7313 13.2164 40.1434C13.2164 40.9364 12.3256 41.9739 11.6302 42.126Z" fill="white"/>
                <path d="M8.96398 37.7395C5.68229 36.9711 6.87192 31.8161 10.5501 31.8162C9.64762 33.7988 10.5501 34.1953 10.5501 35.7569C10.5501 36.5499 9.65935 37.5874 8.96398 37.7395Z" fill="white"/>
                <path d="M6.87187 32.2126C4.49275 30.23 6.47545 27.0577 10.0442 27.0577C8.45808 28.6439 9.35043 29.5423 8.85461 31.023C8.6028 31.775 7.57954 32.2892 6.87187 32.2126Z" fill="white"/>
                <path d="M5.68131 26.6295C3.8538 24.1292 7.26847 21.1098 10.4407 22.6959C8.5174 23.8503 9.25112 24.6786 7.89329 25.9561C7.31568 26.4995 6.34931 26.8753 5.68131 26.6295Z" fill="white"/>
                <path d="M6.43744 20.9063C5.87818 17.8603 8.06136 16.7479 12.0268 17.9374C10.044 19.5236 10.5004 20.7008 8.72226 21.2612C7.96587 21.4996 6.9322 21.4181 6.43744 20.9063Z" fill="white"/>
                <path d="M8.19172 15.8382C7.63246 12.7922 11.2336 11.1965 14.4059 13.9723C12.4231 15.5585 12.6152 15.791 10.8371 16.3514C10.0807 16.5898 8.68647 16.35 8.19172 15.8382Z" fill="white"/>
                <path d="M11.289 11.7499C10.7297 8.70389 13.9343 8.0242 17.5031 9.88395C15.5204 11.4702 15.7125 11.7027 13.9344 12.2631C13.178 12.5015 11.7837 12.2617 11.289 11.7499Z" fill="white"/>
                <path d="M13.5308 7.10379C13.5308 3.93152 14.7337 3.10455 18.6857 2.34538C18.1171 4.82007 18.4677 5.32181 17.0139 6.48897C15.5956 7.62765 14.2364 7.19754 13.5308 7.10379Z" fill="white"/>
                <path d="M18.9284 51.0208C17.6607 47.7142 13.7288 49.643 12.4409 51.8223C15.3144 51.5635 14.3968 53.6009 16.7591 53.1166C18.3126 52.7981 18.9163 51.7277 18.9284 51.0208Z" fill="white"/>
                <path d="M14.0094 47.281C14.0896 43.7407 9.42467 44.5659 7.26833 46.0913C10.2195 46.9454 8.55079 48.0513 11.0867 48.5023C12.7543 48.7989 13.7118 47.9302 14.0094 47.281Z" fill="white"/>
                <path d="M7.26845 37.7642C7.34867 34.2239 4.09619 33.7988 0.527367 33.7988C2.11351 35.7815 1.32044 36.5745 3.69964 38.1607C5.10894 39.1002 6.97084 38.4134 7.26845 37.7642Z" fill="white"/>
                <path d="M10.1511 43.7218C9.64775 38.9537 6.58228 40.9364 3.30322 40.1433C4.88936 42.126 3.69976 42.1357 6.07895 43.7218C7.48825 44.6613 8.85468 44.5052 10.1511 43.7218Z" fill="white"/>
                <path d="M5.28552 32.6091C5.36573 29.0689 4.49248 28.2473 0.130615 27.0577C0.923686 29.0404 0.307419 31.2731 1.71672 32.2126C3.12262 33.1499 3.69661 33.0064 5.27404 32.612L5.28552 32.6091Z" fill="white"/>
                <path d="M4.6235 26.2647C5.28572 22.2994 2.90652 22.2994 1.32034 20.3167C0.527328 21.1098 0.527324 23.8855 1.32032 25.4717C2.11332 27.0578 4.09611 26.6612 4.6235 26.2647Z" fill="white"/>
                <path d="M5.70655 20.329C7.26844 17.5409 4.49275 15.5583 2.90657 13.5756C2.11355 14.3687 1.71704 17.541 2.51004 19.1271C3.30303 20.7133 5.17916 20.7255 5.70655 20.329Z" fill="white"/>
                <path d="M7.26856 14.7652C10.0443 11.9895 6.872 9.21379 6.47549 7.23109C4.88933 8.4207 3.69982 11.9895 4.49282 13.5757C5.28582 15.1618 6.74118 15.1618 7.26856 14.7652Z" fill="white"/>
                <path d="M10.0442 10.4034C13.613 9.2138 11.6303 4.85195 12.4234 2.86933C8.8546 3.66235 8.06149 7.62755 8.45805 8.42073C8.85461 9.21391 8.45812 9.61037 10.0442 10.4034Z" fill="white"/>
                <path d="M43.3458 48.5819C46.7473 48.5819 46.3694 44.4245 44.8576 42.5348C44.1017 45.1804 42.5899 43.6686 42.212 45.9363C41.9634 47.4275 42.7159 48.3299 43.3458 48.5819Z" fill="white"/>
                <path d="M47.0198 45.5821C50.4205 45.6544 50.527 41.023 47.8814 39.5112C47.0694 42.1402 46.3685 40.6539 45.9424 42.913C45.6623 44.3986 46.3954 45.3168 47.0198 45.5821Z" fill="white"/>
                <path d="M50.9049 41.7789C54.2601 41.2196 53.029 36.7271 50.1491 35.7319C49.8385 38.4658 49.3931 37.5903 49.3932 39.8892C49.3932 40.6451 50.2422 41.634 50.9049 41.7789Z" fill="white"/>
                <path d="M53.4459 37.5981C56.5738 36.8657 55.4399 31.9524 51.9342 31.9524C52.7944 33.8421 51.9342 34.22 51.9342 35.7084C51.9342 36.4643 52.7832 37.4531 53.4459 37.5981Z" fill="white"/>
                <path d="M55.44 32.3303C57.7076 30.4406 55.8179 27.417 52.4164 27.417C53.9282 28.9289 53.0777 29.7852 53.5503 31.1965C53.7903 31.9132 54.7655 32.4033 55.44 32.3303Z" fill="white"/>
                <path d="M56.575 27.0088C58.3168 24.6258 55.0623 21.7479 52.0387 23.2597C53.8719 24.3599 53.1725 25.1494 54.4667 26.367C55.0173 26.8849 55.9383 27.2432 56.575 27.0088Z" fill="white"/>
                <path d="M55.8541 21.554C56.3871 18.6507 54.3063 17.5905 50.5268 18.7242C52.4166 20.2361 51.9816 21.3581 53.6764 21.8922C54.3973 22.1194 55.3825 22.0417 55.8541 21.554Z" fill="white"/>
                <path d="M54.1821 16.7235C54.7151 13.8202 51.2827 12.2993 48.2592 14.945C50.149 16.4568 49.9659 16.6784 51.6607 17.2125C52.3816 17.4398 53.7105 17.2112 54.1821 16.7235Z" fill="white"/>
                <path d="M51.2302 12.8268C51.7632 9.92354 48.7088 9.2757 45.3073 11.0483C47.1971 12.5601 47.014 12.7817 48.7088 13.3159C49.4297 13.5431 50.7586 13.3145 51.2302 12.8268Z" fill="white"/>
                <path d="M49.0934 8.39852C49.0935 5.37496 47.947 4.58676 44.1802 3.86318C44.7222 6.22186 44.388 6.70008 45.7736 7.81253C47.1254 8.89782 48.4209 8.48787 49.0934 8.39852Z" fill="white"/>
                <path d="M43.9489 50.2566C45.1571 47.1051 48.9047 48.9435 50.1322 51.0206C47.3934 50.7739 48.2681 52.7158 46.0164 52.2542C44.5358 51.9507 43.9604 50.9305 43.9489 50.2566Z" fill="white"/>
                <path d="M48.6372 46.6922C48.5607 43.3179 53.007 44.1044 55.0622 45.5583C52.2494 46.3723 53.8399 47.4264 51.4228 47.8563C49.8334 48.1389 48.9208 47.3109 48.6372 46.6922Z" fill="white"/>
                <path d="M55.0622 37.6215C54.9857 34.2472 58.0857 33.842 61.4873 33.842C59.9755 35.7318 60.7314 36.4876 58.4637 37.9994C57.1205 38.8949 55.3459 38.2403 55.0622 37.6215Z" fill="white"/>
                <path d="M52.315 43.3C52.7947 38.7554 55.7165 40.6451 58.8418 39.8892C57.33 41.7789 58.4639 41.7882 56.1962 43.3C54.853 44.1955 53.5506 44.0466 52.315 43.3Z" fill="white"/>
                <path d="M56.952 32.7082C56.8755 29.3339 57.7079 28.5509 61.8652 27.4171C61.1093 29.3068 61.6967 31.4348 60.3535 32.3303C59.0135 33.2236 58.4664 33.0868 56.9629 32.711L56.952 32.7082Z" fill="white"/>
                <path d="M57.583 26.6611C56.9518 22.8817 59.2195 22.8817 60.7313 20.992C61.4872 21.7479 61.4872 24.3935 60.7313 25.9053C59.9755 27.4171 58.0857 27.0391 57.583 26.6611Z" fill="white"/>
                <path d="M56.5508 21.0037C55.0621 18.3464 57.7077 16.4566 59.2195 14.5669C59.9753 15.3228 60.3532 18.3464 59.5974 19.8582C58.8416 21.37 57.0534 21.3817 56.5508 21.0037Z" fill="white"/>
                <path d="M55.0624 15.7007C52.4168 13.0552 55.4403 10.4095 55.8183 8.51979C57.3301 9.65363 58.4638 13.0552 57.708 14.567C56.9522 16.0788 55.565 16.0787 55.0624 15.7007Z" fill="white"/>
                <path d="M52.4165 11.5434C49.0151 10.4096 50.9048 6.25219 50.1489 4.36251C53.5504 5.11836 54.3064 8.89768 53.9284 9.65368C53.5504 10.4097 53.9283 10.7875 52.4165 11.5434Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.119 15.955L32.1191 15.9549L42.0322 33.1881C40.0173 33.4308 37.8317 32.4261 36.8504 31.7652C36.5727 31.5782 36.2474 31.2378 35.8745 30.8476C34.9293 29.8586 33.6779 28.5491 32.1191 28.6031L32.119 28.6031V22.9905H32.1192C32.7828 22.9905 33.3207 22.4242 33.3207 21.7257C33.3207 21.0271 32.7828 20.4608 32.1192 20.4608H32.119V15.955ZM32.119 15.955L22.2061 33.1879C24.221 33.4306 26.4066 32.4259 27.3879 31.765C27.6656 31.578 27.9908 31.2376 28.3638 30.8474C29.3089 29.8584 30.5603 28.549 32.119 28.6029V22.9903C31.4554 22.9903 30.9174 22.424 30.9174 21.7255C30.9174 21.027 31.4554 20.4607 32.119 20.4607V15.955Z" fill="#E33446"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.119 15.955L32.1191 15.9549L42.0322 33.1881C40.0173 33.4308 37.8317 32.4261 36.8504 31.7652C36.5727 31.5782 36.2474 31.2378 35.8745 30.8476C34.9293 29.8586 33.6779 28.5491 32.1191 28.6031L32.119 28.6031V22.9905H32.1192C32.7828 22.9905 33.3207 22.4242 33.3207 21.7257C33.3207 21.0271 32.7828 20.4608 32.1192 20.4608H32.119V15.955ZM32.119 15.955L22.2061 33.1879C24.221 33.4306 26.4066 32.4259 27.3879 31.765C27.6656 31.578 27.9908 31.2376 28.3638 30.8474C29.3089 29.8584 30.5603 28.549 32.119 28.6029V22.9903C31.4554 22.9903 30.9174 22.424 30.9174 21.7255C30.9174 21.027 31.4554 20.4607 32.119 20.4607V15.955Z" fill="url(#paint0_linear_1128_449)"/>
                <g filter="url(#filter0_d_1128_449)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1186 15.9548L32.1186 15.9548V20.4607H32.1187C32.7823 20.4607 33.3203 21.027 33.3203 21.7255C33.3203 22.4241 32.7823 22.9904 32.1187 22.9904H32.1186V24.8196C32.1927 24.8123 32.2678 24.8086 32.344 24.8086C34.2215 24.8086 35.6484 26.7058 37.601 29.8678C38.9152 31.9961 40.629 32.8017 41.9973 33.128L32.1186 15.9548ZM32.1186 24.8196C30.6644 24.9623 29.613 26.4763 28.414 28.2028C27.8691 28.9873 27.2939 29.8157 26.6365 30.5793C25.3143 32.115 23.5048 32.8829 22.2058 33.187L32.1183 15.9548L32.1186 15.9553V20.4608C31.4551 20.461 30.9172 21.0272 30.9172 21.7256C30.9172 22.4241 31.4551 22.9903 32.1186 22.9904V24.8196Z" fill="url(#paint1_linear_1128_449)"/>
                </g>
                <g filter="url(#filter1_d_1128_449)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2673 15.8156L42.3983 33.4274L41.9513 33.3208C40.5433 32.9851 38.7796 32.1539 37.4323 29.972C36.4555 28.3901 35.6204 27.1423 34.8014 26.2898C33.9847 25.4398 33.2068 25.0069 32.344 25.0069C32.2744 25.0069 32.2059 25.0103 32.1385 25.0169C32.1383 25.0169 32.1387 25.0168 32.1385 25.0169C31.4785 25.0816 30.8925 25.4585 30.3128 26.0554C29.732 26.6535 29.179 27.4487 28.5768 28.3159L28.5742 28.3197C28.031 29.1018 27.4509 29.9373 26.7867 30.7087C25.4289 32.2858 23.5766 33.0697 22.251 33.38L21.8062 33.4841L31.9639 15.8256L32.1173 15.5549L32.1185 15.5569L32.1196 15.5549L32.2673 15.8156ZM31.9203 24.6464C31.2039 24.7699 30.5913 25.1995 30.0283 25.7792C29.4225 26.403 28.8522 27.2241 28.2579 28.08L28.2511 28.0897C27.7054 28.8755 27.1358 29.6954 26.4862 30.4499C25.3467 31.7735 23.8306 32.5145 22.6148 32.8736L31.9203 16.6967V20.2771C31.2344 20.3782 30.719 20.9962 30.719 21.7256C30.719 22.4551 31.2344 23.073 31.9203 23.1742V24.6464ZM32.3169 24.6105C32.3259 24.6104 32.335 24.6103 32.344 24.6103C33.3587 24.6103 34.2331 25.126 35.0873 26.0151C35.9392 26.9017 36.7939 28.1835 37.7697 29.7637C38.9075 31.6062 40.3473 32.4276 41.5858 32.8102L32.3169 16.6971V20.2769C33.003 20.3779 33.5186 20.9959 33.5186 21.7255C33.5186 22.4552 33.003 23.0732 32.3169 23.1742V24.6105ZM32.1357 20.6591C32.6729 20.6687 33.1221 21.133 33.1221 21.7255C33.1221 22.3243 32.6634 22.7921 32.1187 22.7921H32.1115C31.5701 22.788 31.1155 22.3217 31.1155 21.7256C31.1155 21.127 31.5741 20.6592 32.1186 20.6591L32.1357 20.6591Z" fill="url(#paint2_linear_1128_449)" fill-opacity="0.1"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1194 24.8184C32.1932 24.8112 32.268 24.8075 32.344 24.8075C34.2215 24.8075 35.6484 26.7048 37.601 29.8668C38.9152 31.9951 40.629 32.8007 41.9972 33.127L32.1194 15.9553V20.4598C32.7826 20.4602 33.3202 21.0264 33.3202 21.7246C33.3202 22.4229 32.7826 22.989 32.1194 22.9895V24.8184Z" fill="#B41442" fill-opacity="0.59"/>
                <path d="M94.0973 23.8855V12.6091H97.7838C99.0074 12.6091 99.9265 12.8621 100.541 13.368C101.16 13.874 101.47 14.6227 101.47 15.614C101.47 16.1407 101.321 16.6079 101.021 17.0158C100.722 17.4186 100.314 17.7309 99.7974 17.953C100.407 18.1233 100.887 18.4486 101.238 18.9288C101.594 19.4038 101.772 19.9718 101.772 20.6327C101.772 21.6446 101.444 22.4398 100.789 23.018C100.133 23.5963 99.2062 23.8855 98.0084 23.8855H94.0973ZM95.5843 18.6113V22.6695H98.0393C98.7312 22.6695 99.2759 22.4914 99.6735 22.1351C100.076 21.7737 100.278 21.278 100.278 20.6481C100.278 19.2902 99.5393 18.6113 98.0626 18.6113H95.5843ZM95.5843 17.4186H97.8302C98.4808 17.4186 98.9997 17.2559 99.3869 16.9306C99.7793 16.6054 99.9755 16.1639 99.9755 15.6063C99.9755 14.9867 99.7948 14.5375 99.4334 14.2587C99.072 13.9747 98.5221 13.8327 97.7838 13.8327H95.5843V17.4186Z" fill="white"/>
                <path d="M107.232 24.0404C106.097 24.0404 105.172 23.6686 104.46 22.9251C103.747 22.1764 103.391 21.1774 103.391 19.9279V19.6646C103.391 18.8333 103.548 18.0924 103.863 17.4418C104.184 16.7861 104.628 16.2749 105.196 15.9083C105.769 15.5366 106.388 15.3507 107.054 15.3507C108.144 15.3507 108.99 15.7096 109.595 16.4272C110.199 17.1449 110.501 18.1724 110.501 19.5097V20.106H104.824C104.844 20.9321 105.085 21.6007 105.544 22.1119C106.009 22.6179 106.597 22.8709 107.31 22.8709C107.816 22.8709 108.244 22.7676 108.595 22.5611C108.947 22.3546 109.254 22.0809 109.517 21.7402L110.392 22.4217C109.69 23.5008 108.637 24.0404 107.232 24.0404ZM107.054 16.5279C106.476 16.5279 105.991 16.7396 105.598 17.163C105.206 17.5812 104.963 18.1698 104.87 18.9288H109.068V18.8204C109.027 18.0924 108.83 17.5296 108.479 17.132C108.128 16.7293 107.653 16.5279 107.054 16.5279Z" fill="white"/>
                <path d="M117.045 21.6627C117.045 21.2755 116.898 20.976 116.604 20.7643C116.314 20.5475 115.806 20.3616 115.078 20.2067C114.355 20.0518 113.779 19.8659 113.351 19.6491C112.927 19.4322 112.612 19.1741 112.406 18.8746C112.205 18.5751 112.104 18.2189 112.104 17.8058C112.104 17.1191 112.393 16.5382 112.971 16.0632C113.555 15.5882 114.298 15.3507 115.202 15.3507C116.152 15.3507 116.921 15.596 117.51 16.0865C118.103 16.577 118.4 17.2043 118.4 17.9684H116.96C116.96 17.576 116.792 17.2379 116.456 16.9539C116.126 16.6699 115.708 16.5279 115.202 16.5279C114.68 16.5279 114.272 16.6415 113.978 16.8687C113.684 17.0959 113.537 17.3928 113.537 17.7593C113.537 18.1053 113.673 18.366 113.947 18.5416C114.221 18.7171 114.714 18.8849 115.426 19.045C116.144 19.205 116.725 19.3961 117.169 19.6181C117.613 19.8401 117.941 20.1086 118.153 20.4235C118.369 20.7333 118.478 21.1128 118.478 21.562C118.478 22.3107 118.178 22.9122 117.579 23.3666C116.98 23.8158 116.203 24.0404 115.248 24.0404C114.577 24.0404 113.983 23.9216 113.467 23.6841C112.951 23.4466 112.545 23.1161 112.251 22.6928C111.962 22.2642 111.817 21.8021 111.817 21.3064H113.25C113.276 21.7866 113.467 22.1687 113.823 22.4527C114.185 22.7315 114.66 22.8709 115.248 22.8709C115.79 22.8709 116.224 22.7625 116.549 22.5456C116.88 22.3236 117.045 22.0293 117.045 21.6627Z" fill="white"/>
                <path d="M122.288 13.4765V15.5056H123.853V16.6131H122.288V21.8099C122.288 22.1455 122.358 22.3985 122.497 22.5688C122.637 22.7341 122.874 22.8167 123.21 22.8167C123.375 22.8167 123.602 22.7857 123.891 22.7237V23.8855C123.515 23.9887 123.148 24.0404 122.792 24.0404C122.151 24.0404 121.669 23.8467 121.343 23.4595C121.018 23.0723 120.855 22.5224 120.855 21.8099V16.6131H119.33V15.5056H120.855V13.4765H122.288Z" fill="white"/>
                <path d="M129.692 23.8855V12.6091H132.875C133.856 12.6091 134.724 12.8259 135.478 13.2596C136.231 13.6933 136.812 14.3103 137.22 15.1106C137.633 15.9109 137.842 16.83 137.848 17.8678V18.588C137.848 19.6516 137.641 20.5836 137.228 21.3839C136.82 22.1842 136.234 22.7986 135.47 23.2272C134.711 23.6557 133.825 23.8751 132.813 23.8855H129.692ZM131.179 13.8327V22.6695H132.744C133.89 22.6695 134.781 22.3133 135.416 21.6007C136.056 20.8882 136.376 19.8737 136.376 18.557V17.8987C136.376 16.6183 136.074 15.6244 135.47 14.917C134.871 14.2045 134.019 13.8431 132.914 13.8327H131.179Z" fill="white"/>
                <path d="M143.354 24.0404C142.218 24.0404 141.294 23.6686 140.581 22.9251C139.869 22.1764 139.513 21.1774 139.513 19.9279V19.6646C139.513 18.8333 139.67 18.0924 139.985 17.4418C140.305 16.7861 140.749 16.2749 141.317 15.9083C141.89 15.5366 142.51 15.3507 143.176 15.3507C144.265 15.3507 145.112 15.7096 145.716 16.4272C146.32 17.1449 146.622 18.1724 146.622 19.5097V20.106H140.945C140.966 20.9321 141.206 21.6007 141.666 22.1119C142.13 22.6179 142.719 22.8709 143.432 22.8709C143.937 22.8709 144.366 22.7676 144.717 22.5611C145.068 22.3546 145.375 22.0809 145.639 21.7402L146.514 22.4217C145.812 23.5008 144.758 24.0404 143.354 24.0404ZM143.176 16.5279C142.598 16.5279 142.112 16.7396 141.72 17.163C141.328 17.5812 141.085 18.1698 140.992 18.9288H145.19V18.8204C145.148 18.0924 144.952 17.5296 144.601 17.132C144.25 16.7293 143.775 16.5279 143.176 16.5279Z" fill="white"/>
                <path d="M153.167 21.6627C153.167 21.2755 153.02 20.976 152.725 20.7643C152.436 20.5475 151.928 20.3616 151.2 20.2067C150.477 20.0518 149.901 19.8659 149.472 19.6491C149.049 19.4322 148.734 19.1741 148.528 18.8746C148.326 18.5751 148.226 18.2189 148.226 17.8058C148.226 17.1191 148.515 16.5382 149.093 16.0632C149.676 15.5882 150.42 15.3507 151.323 15.3507C152.273 15.3507 153.043 15.596 153.631 16.0865C154.225 16.577 154.522 17.2043 154.522 17.9684H153.082C153.082 17.576 152.914 17.2379 152.578 16.9539C152.248 16.6699 151.829 16.5279 151.323 16.5279C150.802 16.5279 150.394 16.6415 150.1 16.8687C149.805 17.0959 149.658 17.3928 149.658 17.7593C149.658 18.1053 149.795 18.366 150.069 18.5416C150.342 18.7171 150.836 18.8849 151.548 19.045C152.266 19.205 152.847 19.3961 153.291 19.6181C153.735 19.8401 154.063 20.1086 154.274 20.4235C154.491 20.7333 154.599 21.1128 154.599 21.562C154.599 22.3107 154.3 22.9122 153.701 23.3666C153.102 23.8158 152.325 24.0404 151.37 24.0404C150.699 24.0404 150.105 23.9216 149.589 23.6841C149.072 23.4466 148.667 23.1161 148.373 22.6928C148.084 22.2642 147.939 21.8021 147.939 21.3064H149.372C149.398 21.7866 149.589 22.1687 149.945 22.4527C150.306 22.7315 150.781 22.8709 151.37 22.8709C151.912 22.8709 152.346 22.7625 152.671 22.5456C153.001 22.3236 153.167 22.0293 153.167 21.6627Z" fill="white"/>
                <path d="M158.023 23.8855H156.59V15.5056H158.023V23.8855ZM156.474 13.2829C156.474 13.0505 156.543 12.8543 156.683 12.6943C156.827 12.5342 157.039 12.4542 157.318 12.4542C157.597 12.4542 157.808 12.5342 157.953 12.6943C158.098 12.8543 158.17 13.0505 158.17 13.2829C158.17 13.5152 158.098 13.7088 157.953 13.8637C157.808 14.0186 157.597 14.0961 157.318 14.0961C157.039 14.0961 156.827 14.0186 156.683 13.8637C156.543 13.7088 156.474 13.5152 156.474 13.2829Z" fill="white"/>
                <path d="M159.982 19.6258C159.982 18.3195 160.284 17.2817 160.888 16.5124C161.492 15.7379 162.293 15.3507 163.289 15.3507C164.311 15.3507 165.109 15.7121 165.682 16.435L165.752 15.5056H167.061V23.6841C167.061 24.7684 166.738 25.6229 166.093 26.2476C165.452 26.8724 164.59 27.1847 163.506 27.1847C162.902 27.1847 162.311 27.0557 161.732 26.7975C161.154 26.5393 160.713 26.1857 160.408 25.7365L161.152 24.8768C161.766 25.6358 162.517 26.0153 163.405 26.0153C164.102 26.0153 164.644 25.8191 165.032 25.4267C165.424 25.0343 165.62 24.4818 165.62 23.7693V23.049C165.047 23.7099 164.265 24.0404 163.274 24.0404C162.293 24.0404 161.497 23.6454 160.888 22.8554C160.284 22.0654 159.982 20.9889 159.982 19.6258ZM161.423 19.7885C161.423 20.7333 161.616 21.4768 162.003 22.019C162.391 22.5559 162.933 22.8244 163.63 22.8244C164.533 22.8244 165.197 22.4139 165.62 21.593V17.7671C165.181 16.9668 164.523 16.5666 163.645 16.5666C162.948 16.5666 162.404 16.8377 162.011 17.3798C161.619 17.922 161.423 18.7249 161.423 19.7885Z" fill="white"/>
                <path d="M170.585 15.5056L170.631 16.5589C171.271 15.7534 172.108 15.3507 173.14 15.3507C174.911 15.3507 175.805 16.3498 175.82 18.3479V23.8855H174.387V18.3402C174.382 17.7361 174.243 17.2895 173.969 17.0003C173.701 16.7112 173.28 16.5666 172.707 16.5666C172.242 16.5666 171.834 16.6906 171.483 16.9384C171.132 17.1862 170.858 17.5115 170.662 17.9142V23.8855H169.229V15.5056H170.585Z" fill="white"/>
                <path d="M102.709 36.8518C102.709 37.9412 102.536 38.8809 102.191 39.6709C101.845 40.4557 101.359 41.0701 100.735 41.5141L102.748 43.0941L101.734 44.0312L99.356 42.1415C98.9842 42.2344 98.5918 42.2809 98.1788 42.2809C97.2907 42.2809 96.5033 42.064 95.8166 41.6303C95.1299 41.1914 94.5955 40.5693 94.2134 39.7638C93.8365 38.9532 93.6429 38.0161 93.6326 36.9525V36.1392C93.6326 35.055 93.821 34.0972 94.1979 33.2659C94.5748 32.4347 95.1067 31.7996 95.7934 31.3607C96.4852 30.9167 97.2752 30.6947 98.1633 30.6947C99.072 30.6947 99.8697 30.9141 100.556 31.353C101.248 31.7918 101.78 32.4243 102.152 33.2504C102.524 34.0714 102.709 35.0317 102.709 36.1315V36.8518ZM101.222 36.1238C101.222 34.7968 100.957 33.7745 100.425 33.0568C99.8981 32.334 99.1443 31.9726 98.1633 31.9726C97.2287 31.9726 96.4904 32.3314 95.9483 33.0491C95.4113 33.7616 95.1351 34.7529 95.1196 36.0231V36.8518C95.1196 38.1426 95.3881 39.1597 95.925 39.9032C96.4672 40.6467 97.2184 41.0185 98.1788 41.0185C99.1391 41.0185 99.8826 40.6699 100.409 39.9729C100.936 39.2707 101.207 38.2665 101.222 36.9602V36.1238Z" fill="white"/>
                <path d="M109.951 41.2973C109.393 41.953 108.575 42.2809 107.496 42.2809C106.602 42.2809 105.921 42.0227 105.451 41.5064C104.986 40.9849 104.751 40.2156 104.746 39.1984V33.7461H106.179V39.1597C106.179 40.4299 106.695 41.0649 107.728 41.0649C108.823 41.0649 109.551 40.657 109.912 39.8413V33.7461H111.345V42.126H109.982L109.951 41.2973Z" fill="white"/>
                <path d="M118.702 42.126C118.62 41.9607 118.553 41.6664 118.501 41.2431C117.835 41.9349 117.04 42.2809 116.116 42.2809C115.29 42.2809 114.611 42.0485 114.079 41.5838C113.552 41.114 113.289 40.5202 113.289 39.8025C113.289 38.93 113.619 38.2536 114.28 37.7734C114.946 37.2881 115.881 37.0454 117.084 37.0454H118.478V36.3871C118.478 35.8863 118.328 35.4887 118.029 35.1944C117.729 34.8949 117.288 34.7452 116.704 34.7452C116.193 34.7452 115.765 34.8743 115.419 35.1324C115.073 35.3906 114.9 35.703 114.9 36.0695H113.459C113.459 35.6513 113.606 35.2486 113.901 34.8614C114.2 34.469 114.603 34.1592 115.109 33.932C115.62 33.7048 116.18 33.5912 116.789 33.5912C117.755 33.5912 118.511 33.8339 119.059 34.3192C119.606 34.7994 119.89 35.4629 119.911 36.3096V40.1665C119.911 40.9358 120.009 41.5477 120.205 42.002V42.126H118.702ZM116.325 41.034C116.774 41.034 117.2 40.9178 117.603 40.6854C118.005 40.4531 118.297 40.151 118.478 39.7793V38.06H117.355C115.599 38.06 114.722 38.5737 114.722 39.6012C114.722 40.0504 114.871 40.4015 115.171 40.6545C115.47 40.9075 115.855 41.034 116.325 41.034Z" fill="white"/>
                <path d="M123.713 42.126H122.281V30.23H123.713V42.126Z" fill="white"/>
                <path d="M127.57 42.126H126.137V33.7461H127.57V42.126ZM126.021 31.5234C126.021 31.291 126.091 31.0948 126.23 30.9348C126.375 30.7747 126.587 30.6947 126.865 30.6947C127.144 30.6947 127.356 30.7747 127.5 30.9348C127.645 31.0948 127.717 31.291 127.717 31.5234C127.717 31.7557 127.645 31.9493 127.5 32.1042C127.356 32.2591 127.144 32.3366 126.865 32.3366C126.587 32.3366 126.375 32.2591 126.23 32.1042C126.091 31.9493 126.021 31.7557 126.021 31.5234Z" fill="white"/>
                <path d="M131.814 31.717V33.7461H133.379V34.8536H131.814V40.0504C131.814 40.386 131.884 40.639 132.023 40.8094C132.163 40.9746 132.4 41.0572 132.736 41.0572C132.901 41.0572 133.128 41.0262 133.418 40.9642V42.126C133.041 42.2292 132.674 42.2809 132.318 42.2809C131.678 42.2809 131.195 42.0872 130.869 41.7C130.544 41.3128 130.382 40.7629 130.382 40.0504V34.8536H128.856V33.7461H130.382V31.717H131.814Z" fill="white"/>
                <path d="M137.801 40.0271L139.753 33.7461H141.286L137.917 43.4193C137.396 44.8134 136.567 45.5104 135.431 45.5104L135.16 45.4872L134.626 45.3865V44.2248L135.013 44.2558C135.498 44.2558 135.875 44.1577 136.144 43.9615C136.417 43.7653 136.642 43.4064 136.817 42.885L137.135 42.033L134.146 33.7461H135.71L137.801 40.0271Z" fill="white"/>
                <defs>
                <filter id="filter0_d_1128_449" x="22.2058" y="14.9548" width="26.7915" height="23.2322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="4" dy="2"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1128_449"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1128_449" result="shape"/>
                </filter>
                <filter id="filter1_d_1128_449" x="21.8062" y="14.5549" width="27.592" height="23.9293" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="4" dy="2"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1128_449"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1128_449" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1128_449" x1="19.5025" y1="31.2118" x2="37.7566" y2="30.2841" gradientUnits="userSpaceOnUse">
                <stop offset="0.31361" stop-color="#891531"/>
                <stop offset="1" stop-color="#EB513E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1128_449" x1="22.6564" y1="26.943" x2="45.4879" y2="26.5149" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE5253"/>
                <stop offset="0.739583" stop-color="#E91315"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1128_449" x1="24.6091" y1="25.52" x2="37.452" y2="25.2346" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D65F28"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
              </svg>          
            </div>
          </div>
          <div class="col-sm-4 popup-show2">
            <nav class="navigations">
              <ul>
                <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
                <li><a routerLink="/mywork" routerLinkActive="active">My Work</a></li>
                <li><a routerLink="/icon" routerLinkActive="active">Icon</a></li>
                <li><a routerLink="/painting" routerLinkActive="active">Painting</a></li>
                <li><a routerLink="/photography" routerLinkActive="active">Photograph</a></li>
                <li><a routerLink="/aboutme" routerLinkActive="active">About Me</a></li>
                <li><a routerLink="/blogs" routerLinkActive="active">Blogs </a></li>
              </ul>
            </nav>
          </div>
          <div class="col-sm-4 popup-show3">
            <div class="letest-updated-item">
              <ul class="letest-updated-item-list">
                <li class="list-updated-li">
                  <a class="linkList" routerLink="/painting" routerLinkActive="active">
                    <div class="updated-item-img">
                      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/menu%2Fpainting-tigers_optimized.jpg?alt=media&token=76de51ae-9fd6-4763-a2b4-d89557efd86b" />
                    </div>
                    <div class="updated-item-content">
                      <h3>Painting Tigers</h3>
                      <p>Painting Tigers regular size </p>
                      <p>30" x 24" inch</p>
                    </div>
                  </a>
                </li>
                <li class="list-updated-li">
                  <a class="linkList" routerLink="/painting" routerLinkActive="active">
                    <div class="updated-item-img">
                      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/menu%2Fportrait-beautiful-girl_optimized.jpg?alt=media&token=1c2d9f1a-fa5a-4951-861a-51da0d088a26" />
                    </div>
                    <div class="updated-item-content">
                      <h3>Beautiful Girl Portrait</h3>
                      <p>Beautiful Girl Portrait regular size </p>
                      <p>30" x 24" inch</p>
                    </div>
                  </a>
                </li>
                <li class="list-updated-li">
                  <a class="linkList" routerLink="/painting" routerLinkActive="active">
                    <div class="updated-item-img">
                      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/menu%2Fchild-cat-painting_optimized.jpg?alt=media&token=208a8ec7-ecf2-458b-9720-6fd2a7da9d99" />
                    </div>
                    <div class="updated-item-content">
                      <h3>Child and Cat Painting</h3>
                      <p>Child and Cat Painting regular size </p>
                      <p>48" x 36"inch</p>
                    </div>
                  </a>
                </li>
                <li class="list-updated-li">
                  <a class="linkList" routerLink="/ganpati-festival" routerLinkActive="active">
                    <div class="updated-item-img">
                      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/menu%2Fganpati-festival_optimized.jpg?alt=media&token=82907ada-2cb6-412c-8d42-b08b57edca83" />
                    </div>
                    <div class="updated-item-content">
                      <h3>Ganpati Festival Photography</h3>
                      <p>Photography</p>
                      <p>2019</p>
                    </div>
                  </a>
                </li>
                <li class="list-updated-li">
                  <a class="linkList" routerLink="/photography" routerLinkActive="active">
                    <div class="updated-item-img">
                      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/menu%2Fphotography_optimized.jpg?alt=media&token=e526ffce-d550-4fed-a901-3acef0dde1be" />
                    </div>
                    <div class="updated-item-content">
                      <h3>Photography</h3>
                      <p>Photography</p>
                      <p>2019</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- <a target="_blank" class="myresume-heder" href="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/my_resume%2FAnnasaheb-kute-resume2024.pdf?alt=media&token=40cf3013-e8b7-444a-9ac0-c52ad1e012c4">
  <span class="myresume-content">
    My resume
  </span> 
  <span class="myresume-icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 13H12.5C12.7761 13 13 13.2239 13 13.5C13 13.7455 12.8231 13.9496 12.5899 13.9919L12.5 14H3.5C3.22386 14 3 13.7761 3 13.5C3 13.2545 3.17688 13.0504 3.41012 13.0081L3.5 13H12.5H3.5ZM7.91012 1.00806L8 1C8.24546 1 8.44961 1.17688 8.49194 1.41012L8.5 1.5V10.292L11.182 7.61091C11.3555 7.43735 11.625 7.41806 11.8198 7.55306L11.8891 7.61091C12.0627 7.78448 12.0819 8.0539 11.9469 8.24877L11.8891 8.31802L8.35355 11.8536C8.17999 12.0271 7.91056 12.0464 7.71569 11.9114L7.64645 11.8536L4.11091 8.31802C3.91565 8.12276 3.91565 7.80617 4.11091 7.61091C4.28448 7.43735 4.5539 7.41806 4.74877 7.55306L4.81802 7.61091L7.5 10.292V1.5C7.5 1.25454 7.67688 1.05039 7.91012 1.00806L8 1L7.91012 1.00806Z" fill="#212121"/>
    </svg>
    </span>
  </a> -->
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      var loc = window.location.href; // returns the full URL
      var windowsMinhight = $(window).height();
      $('body').addClass('balcklogo');
      console.log(loc);
      if (/aboutme/.test(loc)) {
        $('body').addClass('tech');
        $('body').addClass('about-bg');
        $('body').removeClass('home');
      }

      $(".side-bannertab-item").click(function(){
        console.log($(this).hasClass("AboutMe"))
        if($(this).hasClass("AboutMe")){
          $('.AboutMe').addClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).hasClass("Skill")){
          $('.Skill').addClass('active');
          $('.Education').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).hasClass("Education")){
          $('.Education').addClass('active');
          $('.Skill').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).hasClass("WorkExperience")){
          $('.WorkExperience').addClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).hasClass("ContactMe")){
          $('.ContactMe').addClass('active');
          $('.WorkExperience').removeClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.AboutMe').removeClass('active');
        }
      });

      $(window).scroll(function() {
        if ($(this).scrollTop()>250)
         {
            $('.side-bannertab-item').addClass('colorchange');
         }
        else
         {
          $('.side-bannertab-item').removeClass('colorchange');
         };

         if($(this).scrollTop()>0){
          $('.AboutMe').addClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).scrollTop()> windowsMinhight - 400){
          $('.Skill').addClass('active');
          $('.Education').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 2 - 600){
          $('.Education').addClass('active');
          $('.Skill').removeClass('active');
          $('.WorkExperience').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 3 - 700){
          $('.WorkExperience').addClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.AboutMe').removeClass('active');
          $('.ContactMe').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 4 - 800){
          $('.ContactMe').addClass('active');
          $('.WorkExperience').removeClass('active');
          $('.Skill').removeClass('active');
          $('.Education').removeClass('active');
          $('.AboutMe').removeClass('active');
        }
      });

      });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-hand',
  templateUrl: './smart-hand.component.html',
  styleUrls: ['./smart-hand.component.scss']
})
export class SmartHandComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
